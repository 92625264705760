import React, { useState, useEffect } from 'react';
import { Col, InputGroup, Button } from 'react-bootstrap';
import { isItemPartOfLongDelivery } from '../api/items';
import { isItemBlockSofa } from './../api/items';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import { formatEta } from './../hardcodedFiles/helpers';

const Wishlist = props => {
	const { wishlist, removeItem, removeSofaGroup, plusItem, minItem, t } = props;
	const [tempWishlist, setTempwishlist] = useState([]);

	// first check if wishlist contains sofagroup items
	// group sofagroup items together in new card
	useEffect(() => {
		if (wishlist) {
			const grouped = _.groupBy(wishlist, car => car.sofaGroup);
			let wl = Object.keys(grouped).map(gI => {
				if (gI !== 'undefined') {
					return {
						wishlistItems: grouped[gI].map(g => g.wishlistItem),
						sofaGroup: gI
					}
				}
			});
			if (grouped['undefined']) {
				wl = [...wl, ...grouped['undefined']];
			}
			wl = wl.filter(a => a);
			setTempwishlist(wl);
		};
	}, [wishlist]);

	const getEta = (item) => {
		let value = undefined;
		item.wishlistItems.forEach(wI => {
			const it = JSON.parse(wI);
			if ((!value && it.eta.length) || (it.eta.length && value && (new Date(it.eta.split(' ')[0]) > value))) {
				value = new Date(it.eta.split(' ')[0]);
			}
		});
		if (value) {
			const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			return value.toLocaleDateString("en-US", options);
		}
		return '';
	}

	return tempWishlist ? tempWishlist.map((item, index) => {
		if (item.wishlistItem) {
			const wishlistItemParsed = JSON.parse(item.wishlistItem);
			const { imageMedium, size, sku, stock, price, eta } = wishlistItemParsed;
			console.log(wishlistItemParsed)
			return <Col xs={12} key={index}>
				<div className="wrapper-wishlist" >
					<div className="clash-card-wishlist barbarian-wishlist">
						<div className="clash-card__image-wishlist clash-card__image--barbarian-wishlist">
							<img src={imageMedium} alt={'dwd'} style={{}}></img>
						</div>
						<div className="clash-card__level-wishlist clash-card__level--barbarian-wishlist"></div>
						<div className="clash-card__unit-name-wishlist">{item['description.ENU']}</div>
						<div className="clash-card__unit-description-wishlist">
							<NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'€ '} /> <br /> {size} <br /> {sku} <br /> {t('stock')}: {stock} <br /> {isItemPartOfLongDelivery(wishlistItemParsed) && t(isItemBlockSofa(wishlistItemParsed))} <br /> {formatEta(eta, t)}
						</div>
						<div className="clash-card__unit-stats-wishlist clash-card__unit-stats--barbarian-wishlist ">
							<div className="one-third">
								<InputGroup size="sm" className="">
									<InputGroup.Prepend>
										<Button variant="outline-secondary"
											onClick={() => {
												minItem(sku);
											}}>
											<i className="fa fa-minus" aria-hidden="true"></i>
										</Button>
									</InputGroup.Prepend>
									<InputGroup.Prepend>
										<Button variant="outline-secondary">{item.amount}</Button>
									</InputGroup.Prepend>
									<InputGroup.Prepend>
										<Button variant="outline-secondary"
											onClick={() => {
												plusItem(sku);
											}}>
											<i className="fas fa-plus    "></i>
										</Button>
									</InputGroup.Prepend>
									<InputGroup.Prepend>
										<Button variant="danger"
											onClick={() => {
												removeItem(sku);
											}}>
											<i className="fas fa-trash-alt    "></i>
										</Button>
									</InputGroup.Prepend>
								</InputGroup>
							</div>
						</div>
					</div>
				</div>
			</Col>
		} else if (item.wishlistItems) {
			return <Col xs={12} key={index}>
				<div className="wrapper-wishlist" >
					<div className="clash-card-wishlist barbarian-wishlist">
						<div className="clash-card__image-wishlist clash-card__image--barbarian-wishlist">
							<img src={'https://api.ptmd.nl/rest/images/v1/original/configurator/khaki_combi_B.jpg'} alt={'dwd'} style={{}}></img>
						</div>
						<div className="clash-card__level-wishlist clash-card__level--barbarian-wishlist"></div>
						<div className="clash-card__unit-name-wishlist">{item['description.ENU']}</div>
						<div className="clash-card__unit-description-wishlist">
							{
								item.wishlistItems.map(wI => {
									const it = JSON.parse(wI);
									return <>{it.sku}<br /></>
								})
							}
						</div>
						<div className="clash-card__unit-description-wishlist">
							{
								item.wishlistItems.map(wI => {
									const it = JSON.parse(wI);
									return <>{it.name} - {it.price},-<br /></>
								})
							}
						</div>
						<div className="clash-card__unit-description-wishlist">
							ETA: {`${getEta(item)}`}
						</div>
						<div className="clash-card__unit-stats-wishlist clash-card__unit-stats--barbarian-wishlist ">
							<div className="one-third">
								<InputGroup size="sm" className="">
									<InputGroup.Prepend>
										<Button variant="danger"
											onClick={() => {
												removeSofaGroup(item.sofaGroup);
											}}>
											<i className="fas fa-trash-alt    "></i>
										</Button>
									</InputGroup.Prepend>
								</InputGroup>
							</div>
						</div>
					</div>
				</div>
			</Col>
		}
	}) : null;
};

export default Wishlist;