import React from 'react';
import { Col } from 'react-bootstrap';
import { imageUrlConstructor } from './../api/image';
import { skipGroups } from './../hardcodedFiles/skipGroups';
import { translate } from './../hardcodedFiles/translationHelper';

const Category = props => {
	const {data, goToCustom, dispatchCategoryCode, i18n, facet} = props;
	return data ? data.map((item, index) => {
		const {code, image, names} = item;
		const foundCount = facet ? facet.find(f => f.name.toLowerCase() === translate(names, 'nl').toLowerCase()) : null;
		const count = foundCount ? foundCount.count : null;
		return (
			<Col key={index} onClick={
				() => {
					const skip = skipGroups.find(group => group.code === code);
					if (skip) {
						return goToCustom(skip.path);
					}
					return dispatchCategoryCode(code);
				}}
			className='col-pg' sm={6} md={6}>
				<div className="wrapper-pg">
					<div className="clash-card-pg barbarian-pg">
						<div className="clash-card__image-pg clash-card__image--barbarian-pg">
							<img src={imageUrlConstructor(image)} alt={index}></img>
						</div>
						<div className="clash-card__level clash-card__level--barbarian">{translate(names, i18n.language || window.localStorage.i18nextLng)} <span className='count'>{count ? `(${count})` : ''}</span></div>
					</div>
				</div>
			</Col>
		);
	}) : null;
};

export default Category;