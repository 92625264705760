import Axios from 'axios';
import { generalEndpoint, feedbackEndpoint } from '../config/ptc-config';
import { categoryFacetEndpoint, productgroupFacetEndpoint, generalFacetsEndpoint, toggleEtaEndpoint } from './../config/ptc-config';

export const getBasicData = (_id) => {
	return Axios.post(generalEndpoint, { _id }).then(res => res.data);
};

export const categoryFacet = (_id) => {
	return Axios.post(categoryFacetEndpoint, { _id }).then(res => res.data.facet);
};

export const productgroupFacet = (_id, category) => {
	return Axios.post(productgroupFacetEndpoint, { _id, category }).then(res => res.data.facet);
};

export const getGeneralFacets = (_id, params) => {
	return Axios.post(generalFacetsEndpoint, { _id, ...params }).then(res => res.data.facet);
};

export const sendFeedback = (feedback) => {
	return Axios.post(feedbackEndpoint, { feedback }).then(res => res);
};

export const toggleEta = (_id, on) => {
	return Axios.post(toggleEtaEndpoint, { _id, eta: on }).then(res => res.data);
};