/* eslint-disable no-magic-numbers */
const useLocalEndpoint = () => {
	if (window.location.hostname === 'localhost') {
		return `http://localhost:${48081}/`;
	} else if (window.location.hostname.includes('beta') || window.location.hostname.includes('staging')) {
		return 'https://staging.api.ptmd.nl/rest/b2c/v1/';
	}
	return 'https://api.ptmd.nl/rest/b2c/v1/';
};
export const endpoint = useLocalEndpoint();
export const dataEndpoint = endpoint + 'data';
export const itemEndpoint = endpoint + 'item';
export const suggestEndPoint = endpoint + 'suggest';
export const generalEndpoint = endpoint + 'getGenerals';
export const searchSuggestionsEndpoint = endpoint + 'searchsuggest';
export const conductSearchEndpoint = endpoint + 'conductsearch';
export const suggestItemsProviderEndPoint = endpoint + 'givesuggestitems';
export const itemImageEndpoint = 'https://api.ptmd.nl/rest/images/v1/bigger/';
export const itemImageOriginalEndpoint = 'https://api.ptmd.nl/rest/images/v1/big/';
export const categoriesEndpoint = endpoint + 'categories'; //CURRENTLY NOT IN USE
export const productGroupEndpoint = endpoint + 'allproductgroups'; //CURRENTLY NOT IN USE
export const createWishtListItemEndpoint = endpoint + 'createwishlistitem';
export const createWishtListEndpoint = endpoint + 'createwishlist';
export const saveOrderEndpoint = endpoint + 'saveorder';
export const addItemToWishlistEndpoint = endpoint + 'additemtowishlist';
export const addItemsToWishlistEndpoint = endpoint + 'additemstowishlist';
export const removeItemFromWishlistEndpoint = endpoint + 'removeitemfromwishlist';
export const addItemAmountToWishlistEndpoint = endpoint + 'additemamounttowishlist';
export const removeItemAmountFromWishlistEndpoint = endpoint + 'removeitemamountfromwishlist';
export const getWishlistEndpoint = endpoint + 'getwishlist';
export const removeSofaGroupFromWishlistEndpoint = endpoint + 'removesofagroupfromwishlist';
export const itemsOnWishlistEndpoint = endpoint + 'itemsonwishlist';
export const suggestrelateditemsEndpoint = endpoint + 'suggestrelateditems';
export const carouselEndpoint = endpoint + 'carouselitems';
export const suggestRelatedSizesEndpoint = endpoint + 'suggestrelatedsizes';
export const toggleEtaEndpoint = endpoint + 'toggleeta';
export const getLanguageEndpoint = endpoint + 'getlanguage';
export const catalogfrontEndpoint = endpoint + 'catalogfront';
export const categoryFacetEndpoint = endpoint + 'categoryfacet';
export const productgroupFacetEndpoint = endpoint + 'productgroupfacet';
export const generalFacetsEndpoint = endpoint + 'generalfacets';
export const feedbackEndpoint = endpoint + 'feedback';
export const catalogEndpoint = (code) => endpoint + 'catalog/' + code;
export const pageAmount = 60;