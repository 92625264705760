
import Axios from 'axios';
import { searchSuggestionsEndpoint } from '../config/ptc-config';
import { conductSearchEndpoint } from './../config/ptc-config';

export const getSuggestions = (_id, suggestion, crumbles, chips, searchQuery) => {
	return Axios.post(searchSuggestionsEndpoint, {_id, suggestion, crumbles, chips, searchQuery}).then(res => res.data);
};

export const conductSearch = (_id, searchterm, page, sort) => {
	return Axios.post(conductSearchEndpoint, {_id, searchterm, page, sort}).then(res => res.data);
};