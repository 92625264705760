import React, { useState, useEffect } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import Search from './../views/search';
import '../styles/search.css';
import { useTranslation } from 'react-i18next';
import { getSuggestions } from './../api/search';
import { useQueryParam, StringParam } from 'use-query-params';
import { useSelector } from 'react-redux';
import combinedProductGroups from '../hardcodedFiles/combinedProductGroups';


const SearchContainer = props => {
	const {zuilid} = useParams();
	const [page] = useQueryParam('page', StringParam);
	const history = useHistory();
	const [searchCrumble, setSearchCrumble] = useState();
	const {
		_id,
		crumbles,
		generalFacets
	} = useSelector(
		state => ({
			_id: state.main._id,
			crumbles: state.main.crumbles,
			generalFacets: state.main.generalFacets
		})
	);
	const [suggestions, setSuggestions] = useState();
	const search = (suggestion, chips) => {
		getSuggestions(zuilid, suggestion, crumbles, chips).then(data => {
			setSuggestions(data);
		});
	};
	const getQueryString = (searchQuery) => {
		const queryMap = searchQuery.map(o => {
			let combinedCodeOrNot;
			if (o.code && combinedProductGroups[o.code]) {
				combinedCodeOrNot = combinedProductGroups[o.code];
			} else if (o.code) {
				combinedCodeOrNot = o.code;
			}
			return {type: o.type, value: o.value.replace('&', '@@'), special: o.special, code: combinedCodeOrNot};
		});
		return `?page=${page ? page : 1}&search=on&searchQuery=${JSON.stringify(queryMap)}`;
	};
	const find = (query) => {
		if (query.length) {
			history.push(`/${_id}/search${getQueryString(query)}`);
		}
	};
	const hitSkuSuggestion = (sku) => {
		history.push(`/${_id}/item/${sku}`);
	};
	const emptyQuery = () => {
		// if (!crumbles || crumbles && !crumbles.category) {
		// 	history.push(`/${_id}`);
		// } else if (crumbles.productgroup) {
		// 	history.push(`/${_id}/${crumbles.category}/${crumbles.productgroup}`);
		// } else if (crumbles.category) {
		// 	history.push(`/${_id}/${crumbles.category}`);
		// }
		history.push(`/${_id}`);

	};
	const { t } = useTranslation();
	useEffect(() => {
		if (!searchCrumble && crumbles) {
			setSearchCrumble(crumbles);
		}
	}, [crumbles]);
	return (
		<Search
			t={t}
			search={search}
			suggestions={suggestions}
			conductSearch={find}
			generalFacets={generalFacets}
			setShowFab={props.setShowFab}
			emptyQuery={emptyQuery}
			crumbles={crumbles}
			hitSkuSuggestion={hitSkuSuggestion}
		/>
	);
};

export default withRouter(SearchContainer);