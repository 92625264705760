import React, { useEffect, useState } from 'react';
import { useRouteMatch, withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getItem, addItemToWishlist } from '../api/items';
import Item from '../views/item';
import '../styles/item.css';
import { setWishListLength } from '../actions/actions';
import withPage from '../hoc/withPage';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ItemContainer = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [item, setItem] = useState();
	const history = useHistory();
	const match = useRouteMatch({
		path: '/:zuilid/item/:sku'
	});
	const { zuilid } = match.params;
	const goToSuggestion = (sku) => {
		history.push(`/${zuilid}/item/${sku}`);
	};
	const {
		etaEnabled
	} = useSelector(
		state => ({
			etaEnabled: state.main.etaEnabled
		})
	);
	const addToWishlist = (i) => {
		const {
			campaign,
			collection,
			color,
			eta,
			id,
			imageLarge,
			imageMedium,
			imageSmall,
			otherColor,
			price,
			productgroup,
			size,
			sku,
			stock,
			innerBoxQuantity,
			categoryCode
		} = i;
		const desc = i['description.ENU'];
		const it = {
			campaign,
			collection,
			color,
			eta,
			id,
			imageLarge,
			imageMedium,
			imageSmall,
			otherColor,
			price,
			productgroup,
			size,
			sku,
			stock,
			innerBoxQuantity,
			categoryCode,
			'description.ENU': desc
		};
		addItemToWishlist(it, zuilid).then(a => {
			dispatch(setWishListLength(a));
		});
	};
	useEffect(() => {
		const { sku } = match.params;
		getItem(sku, zuilid).then(i => {
			setItem(i);
		});
	}, []);
	return (
		<Item
			item={item}
			goToSuggestion={goToSuggestion}
			addToWishlist={addToWishlist}
			t={t}
			i18n={i18n}
			etaEnabled={etaEnabled}
		/>
	);
};

export default withRouter(withPage({ title: 'item' })(ItemContainer));