import React, { useEffect } from 'react';
import { withRouter, useHistory, useRouteMatch } from 'react-router-dom';
import { CATEGORIES } from './../hardcodedFiles/categories';
import Category from '../views/category';
import '../styles/category.css';
import { useDispatch } from 'react-redux';
import { reset } from '../actions/actions';
import withPage from '../hoc/withPage';
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';
import { setWishListLength } from './../actions/actions';
import { addItemsToWishlist } from './../api/items';

const CategoryContainer = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const [injectIntoCart] = useQueryParam('injectIntoCart', StringParam);
	const match = useRouteMatch({
		path: '/:zuilid'
	});
	const dispatch = useDispatch();
	const dispatchCategoryCode = (categoryCode) => {
		const {zuilid} = match.params;
		const url = `/${zuilid}/browse/${categoryCode}`;
		history.push(url);
	};
	const goToCustom = (path) => {
		const {zuilid} = match.params;
		history.push(`/${zuilid}/browse${path}`);
	};
	const runCartInjection = () => {
		const {zuilid} = match.params;
		addItemsToWishlist(injectIntoCart, zuilid).then(a => {
			dispatch(setWishListLength(a));
			window.location = window.location.origin + window.location.pathname;
		});
	}
	useEffect(() => {
		dispatch(reset());
		if (injectIntoCart) {
			runCartInjection()
		}
	}, []);
	return (
		<Category
			data={CATEGORIES}
			dispatchCategoryCode={dispatchCategoryCode}
			goToCustom={goToCustom}
			t={t}
			i18n={i18n}
		/>
	);
};

export default withRouter(withPage({title: 'category'})(CategoryContainer));