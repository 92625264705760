import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import Keyboard from 'react-simple-keyboard';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SuggestionCard from './suggestionCard';
import NoSuggestions from './noSuggestions';
import keyboardLayouts from '../hardcodedFiles/keyboardLayouts';

const mapping = {
	categoryNamesSuggester:  'In categorieën',
	productGroupNameSuggester: 'In productgroepen',
	colorSuggester: 'In kleuren',
	skuSuggester: 'In productnummers',
	collectionSuggester: 'In collecties',
	descrSuggester: 'In producten'
};

const useStyles = makeStyles(() => ({
	root: {
		padding: '0px 4px',
		display: 'flex',
		alignItems: 'center',
		boxShadow: 'none'
	},
	input: {
		flex: 1,
		borderBottom: 'none'
	},
	iconButton: {
		padding: 10
	},
	divider: {
		height: 28,
		margin: 4
	},
	input2: {
		padding: '20px'
	},
	label: {
		fontWeight: '000'
	},
	chip: {
		margin: '5px 0 10px 5px'
	}
}));

const Search = props => {
	const {t, search, suggestions, conductSearch, setShowFab, emptyQuery, crumbles, hitSkuSuggestion} = props;
	const inputRef = useRef();
	const keyboard = useRef();
	// const [scrolled, setScrolled] = useState(window.pageYOffset);
	const [open, setOpen] = useState(false);
	const [keys, setKeys] = useState('default');
	const [searchSuggestions, setSearchSuggestions] = useState();
	const classes = useStyles();
	const [keyboardVal, setKeyboardVal] = useState('');
	const [chips, setChips] = useState([]);
	const setSearchBarOpen = bool => {
		setOpen(bool);
		setShowFab(!bool);
		if (!bool) { setKeys('default'); setSearchSuggestions([]); }
	  };
	// const handleScroll = () => {
	// 	const currentScrollPos = window.pageYOffset;
	// 	setSearchBarOpen(scrolled > currentScrollPos);
	// 	setScrolled(currentScrollPos);
	// 	setKeyboardVal('');
	// 	if (keyboard.current && inputRef.current) {
	// 		keyboard.current.setInput('');
	// 		inputRef.current.blur();
	// 	}
	// };
	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll);
	// }, []);
	const onChange = input => {
		setKeyboardVal(input);
		inputRef.current.focus();
	  };
	  useEffect(() => {
		  if (keyboardVal.length) {
			search(keyboardVal, chips);
		  }
	  }, [keyboardVal]);
	  useEffect(() => {
		let newChips;
		if (crumbles && crumbles.productgroup) {
			if (!chips.find(c => c.value === crumbles.productgroup)) {
				newChips = [{
					value: crumbles.pt,
					type: 'productGroupNameSuggester',
					code: crumbles.productgroup,
					special: true,
					...crumbles
				}];
			}
		} else if (crumbles && crumbles.category) {
			if (!chips.find(c => c.value === crumbles.category)) {
				newChips = [{
					value: crumbles.ct,
					type: 'categoryNamesSuggester',
					code: crumbles.category,
					special: true,
					...crumbles
				}];
			}
		} else if (crumbles && crumbles.sku) {
			newChips = chips;
		} else if (crumbles && !crumbles.category) {
			newChips = [];
		}
		setChips(newChips || chips);
	  }, [crumbles]);

	  useEffect(() => {
		setSearchSuggestions(suggestions);
	  }, [suggestions]);

	  const handleClick = () => {
		setSearchBarOpen(true);
		setKeys('default');
		inputRef.current.focus();
	  };
	  const handleClickAway = () => {
		setKeyboardVal('');
		if (keyboard && keyboard.current) keyboard.current.setInput('');
		setSearchBarOpen(false);
		inputRef.current.blur();
	  };
	  const clickOnSuggestion = (suggestion) => {
		if (chips.find(c => c.value === suggestion.value)) {
			return;
		}
		const newChips = [...chips];
		setKeyboardVal('');
		if (keyboard && keyboard.current) keyboard.current.setInput('');
		newChips.push(suggestion);
		if (suggestion.type === 'skuSuggester') {
			setSearchBarOpen(false);
			return hitSkuSuggestion(suggestion.value);
		}
		if (suggestion.type === 'collectionSuggester') {
			setSearchBarOpen(false);
		}
		setChips(newChips);
		conductSearch(newChips);
		if (newChips.find(c => c.special) && newChips.length === 2) {
			return setSearchBarOpen(false);
		}
		if (newChips.length === 1) {
			return setSearchBarOpen(false);
		}
		inputRef.current.focus();
	  };
	  const sortSuggestions = (a) => {
		if (!a) return;
		const obj = {};
		a.forEach(s => {
			obj[s.type] = obj[s.type] ? [...obj[s.type], s]: [s];
		});
		const titles = Object.keys(obj);
		return titles.map(title => {
			return <div className='innerBox'>
				<div className='boxTitle'>
					{
						mapping[title]
					}
				</div>
				<div className='boxSuggestions'>
					{
						obj[title].map((sug, i) => {
							return <SuggestionCard key={i} chips={chips} type={sug.type} value={sug.value} item={sug.item} onClick={() => clickOnSuggestion(sug)} />;
						})
					}
				</div>

			</div>;
		});
	  };
	  const handleDeleteChip = (chip, index) => {
		const c = [...chips];
		c.splice(index, 1);
		setChips(c);
		search(keyboardVal, c);
		if (c.length) {
			inputRef.current.focus();
			return conductSearch(c);
		}
		return emptyQuery();
	  };
	  const openSku = () => {
		setChips([]);
		setKeys('sku');
		setSearchSuggestions();
		inputRef.current.focus();
		setSearchBarOpen(true);
	  };
	  const normalKeys = () => {
		setKeys('default');
		inputRef.current.focus();
	  };

	return (
		<>

			<ClickAwayListener onClickAway={() => handleClickAway()}>
				<div className={classes.wrapper}>
					<div className='inputRow'>
						<ChipInput
							ref={inputRef}
							value={chips.map(c => c.value)}
							onDelete={(chip, index) => handleDeleteChip(chip, index)}
							InputProps={{readOnly: true, classes: {underline: classes.underline}}}
							inputValue={keyboardVal}
							onClick={() => handleClick()}
							style={{width: '100%'}}
							label={<span className='labelSearch'>{t('search')}<i className="fas fa-search    "></i></span>}
							classes={{
								chip: classes.chip,
								label: classes.label,
								input: classes.input
							}}
						/>
						<div className='iconDivHolder'>

							<div className='icons' onClick={() => openSku()}>
								<span >Item Nr.</span>
								<i className="fas fa-search    "></i>
							</div>
						</div>
					</div>
					{open &&
					<>
						<div className='dropdownBox' >
							<div className='box' >
								{
									searchSuggestions && searchSuggestions.length ? sortSuggestions(searchSuggestions) : keyboardVal.length ? <NoSuggestions text={t('noresults')} keys={keys} typist /> : <NoSuggestions keys={keys} typist text={t(keys === 'sku' ? 'sku' : 'starttyping')} />
								}
							</div>
							{
								keys === 'sku' &&
								<div className='ABCholder' onClick={() => normalKeys()}>
									<div className='ABC'>
										<span>ABC</span>
									</div>
								</div>
							}
							<Keyboard
								// eslint-disable-next-line no-return-assign
								keyboardRef={r => keyboard.current = r}
								onChange={input => onChange(input)}
								layoutName={keys}
								layout={keyboardLayouts}
								display={{
									'{bksp}': '<',
									'{space}': 'space'
								}}
							/>

						</div>
						<div className='closeSug' onClick={(e) => handleClickAway(e)}>
							<i className="fas fa-times"></i>
						</div>
						<div className='searchOverlay' draggable='true' onDragStartCapture={e => console.log(e)} onClick={(e) => handleClickAway(e)}></div>
						</>
					}
				</div>
			</ClickAwayListener>
		</>
	);
};


export default Search;