import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './main/store.js';
import { CustomRouter } from './main/routingHandling';
import './styles/general.css';

ReactDOM.render(
	<Provider store={store}>
		<CustomRouter />
	</Provider>
	, document.getElementById('root'));

