import React, { useState } from 'react';
import { Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { isItemPartOfLongDelivery } from '../api/items';
import { renderPinFunction } from './../containers/pin';
import { formatEta } from './../hardcodedFiles/helpers';

const Checkout = props => {
	const { wishlist, order, t } = props;
	const [name, changeName] = useState('');
	const [pin, setPin] = useState('');

	const checkForEta = () => {
		let latestEta = undefined;
		wishlist && wishlist.map(item => {
			const { wishlistItem } = item;
			const wishlistItemParsed = JSON.parse(wishlistItem);
			const { eta } = wishlistItemParsed;
			if (eta && !latestEta) {
				latestEta = eta;
			} else if (eta && latestEta && new Date(eta) > new Date(latestEta)) {
				latestEta = eta;
			}
		});
		if (latestEta) {
			return `Deze bestelling zal in de week van ${formatEta(latestEta, t, true)} geleverd worden`;
		}
	}

	return (
		<>
			<Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
				<i className="fas fa-user"></i>
				<hr className='hr' />
				<div>
					<form autoComplete="off">
						<TextField
							required
							label={t('name')}
							onChange={(n) => changeName(n.target.value)}
							margin="normal"
							variant="outlined"
							value={name}
						/>
					</form>
				</div>
				{
					renderPinFunction(
						(p) => setPin(pin + p),
						() => setPin(pin.slice(0, pin.length - 1)),
						null,
						<InputGroup>
							<FormControl
								value={pin}
								type="password"
								placeholder={t('enterpin')}
								readOnly
							/>
						</InputGroup>
					)
				}
				{
					checkForEta()
				}
				<Button style={{ marginTop: '10px' }} variant='dark' onClick={() => order(pin, name)}>
					{t('place order')}
				</Button>
			</Col>
			<Col xs={6} >
				<i className="fas fa-list"></i>
				<hr className='hr' />
				<div className='summary'>
					{
						wishlist && wishlist.map(item => {
							const { amount, wishlistItem } = item;
							const wishlistItemParsed = JSON.parse(wishlistItem);
							return (
								<Col key={wishlistItemParsed['sku']} xs={6}>
									<br />
									<span>sku: {wishlistItemParsed['sku']}</span>
									<br />
									<span> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(parseFloat(wishlistItemParsed['price']) * amount)}</span>
									<br />
									<span>amount: {amount}</span>
									<br />
									{
										isItemPartOfLongDelivery(wishlistItemParsed) ? <span style={{ color: 'darkred' }}>{t('longdelivery')}</span> : null
									}
									< br />
									{formatEta(wishlistItemParsed.eta, t)}
									<br />
									<img src={wishlistItemParsed.imageSmall} alt={wishlistItemParsed.imageSmall} className='summaryImage'></img>
								</Col>
							);
						})
					}
				</div>

			</Col>
			<Col xs={12}>
				<br />
				Pot en Mand B.V. verwerkt geen persoonsgegevens buiten het afronden van dit bestelproces. Ook gebruiken we geen social media plugins. Nadat deze order is verwerkt zullen de klantgegevens periodiek verwijderd worden.
        </Col>
		</>
	);
};

export default Checkout;