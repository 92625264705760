export default
{
	'default': [
		'1 2 3 4 5 6 7 8 9 0 {bksp}',
		'q w e r t y u i o p',
		'a s d f g h j k l',
		'z x c v b n m , .',
		'{space}'
	],
	'sku': [
		'1 2 3',
		'4 5 6',
		'7 8 9',
		'0 {bksp}'
	]
};
