import pots from '../resources/images/new/PottenS.a7b80aad.jpg';
import potm from '../resources/images/new/PottenM.26f11955.jpg';
import potl from '../resources/images/new/PottenL.e8233f8d.jpg';
import vaass from '../resources/images/new/VazenS.1e8eb322.jpg';
import vaasm from '../resources/images/new/VazenM.5c5b19f2.jpg';
import vaasl from '../resources/images/new/VazenL.fc9c8c8b.jpg';

import kunstplanten from '../resources/images/productgroups/Kunstplanten/Kunstplanten.jpg';

import kussenhoezen from '../resources/images/new/Kussenhoezen.c0619aad.jpg';
import plaids from '../resources/images/new/Plaids.a08a686f.jpg';
import verf from '../resources/images/new/Verf.5ccc6df9.jpg';
import vloerkleden from '../resources/images/new/Vloerkleden.9e1ee77e.jpg';

// import bestek from '../resources/images/productgroups/Keuken/Bestek.jpg';
import servies from '../resources/images/new/Servies.0d2ced8b.jpg';
import glaswerk from '../resources/images/new/Glaswerk.471f370e.jpg';
import dienbladen from '../resources/images/new/Dienbladen.02da9d54.jpg';

import kasten from '../resources/images/new/Kasten.24d14ffa.jpg';
// import tafels from '../resources/images/productgroups/Meubels/Tafels.jpg';
import stoelen from '../resources/images/new/Stoelen.a8a2756c.jpg';
import krukjes from '../resources/images/productgroups/Meubels/Krukjes.jpg';
// import tuinmeubelen from '../resources/images/productgroups/Meubels/Tuinmeubelen.png';
// import banken from '../resources/images/new/Banken.751a2a91.jpg';

import decowandpaneel from '../resources/images/productgroups/Wanddecoratie/Decowandpaneel.jpg';
import wandhaken from '../resources/images/new/Wandhaken.7b1ceb2f.jpg';
import klokken from '../resources/images/new/Klokken.a1531bed.jpg';
import spiegel from '../resources/images/new/Spiegels.20ac6258.jpg';
import schilderijen from '../resources/images/new/Schilderijen.55079725.jpg';

import hanglampen from '../resources/images/new/Hanglampen.9ea07635.jpg';
import wandlampen from '../resources/images/new/Wandlampen.b457ef2a.jpg';
import staandelampen from '../resources/images/new/Staandelampen.44d5a04b.jpg';
import tafellampen from '../resources/images/new/Tafellampen.13fa1d26 (1).jpg';
import lampenkappen from '../resources/images/new/Lampenkappen.67c49ccb.jpg';

import Windlichten from '../resources/images/new/Windlichten.44c23ad4.jpg';
import Standbeelden from '../resources/images/new/Standbeelden.45cc9b1c.jpg';
import Manden from '../resources/images/new/Manden.06d7057c.jpg';
import Fotolijsten from '../resources/images/new/Fotolijsten.87b8e4a2.jpg';
import Stolp from '../resources/images/productgroups/Accessoires/Stolp.jpg';
import Etagere from '../resources/images/new/Etagere.308c8535.jpg';
// import Wijnkoelers from '../resources/images/productgroups/Accessoires/wijnkoelers.jpg';
import Opbergen from '../resources/images/productgroups/Accessoires/Opbergen.jpg';
import Decoratiehanger from '../resources/images/new/Decoratiehanger.dbf98cef.jpg';
import { imageUrlConstructor } from './../api/image';

import eettafels from '../resources/images/productgroups/Meubels/Eettafels.jpg';
import koffietafels from '../resources/images/productgroups/Meubels/Koffietafels.jpg';
import bijzettafels from '../resources/images/productgroups/Meubels/Bijzettafels.jpg';
import fauteauil from '../resources/images/productgroups/Meubels/Fauteauil.jpg';
import barkruk from '../resources/images/productgroups/Meubels/Barkrukken.jpg';
import poefs from '../resources/images/productgroups/Meubels/Poefs.jpg';
import neb from '../resources/images/productgroups/Meubels/Non-Element-banken.jpg';
import eb from '../resources/images/productgroups/Meubels/Element-banken.jpg';

export const PRODUCTGROUPS = [//catCode
	{
		category: '3',
		productgroupsitem: [

			{
				productgroup: '11111', //for onclicktoitems
				name: '',
				image: pots,
				'names': {
					'FRA': 'Pot S ',
					'DEU': 'Topf S ',
					'NLD': 'Pot S ',
					'ENU': 'Pot S '
				}
			},
			{
				productgroup: '11112', //for onclicktoitems
				name: 'Potten medium',
				image: potm,
				'names': {
					'FRA': 'Pot M ',
					'DEU': 'Topf M ',
					'NLD': 'Pot M ',
					'ENU': 'Pot M '
				}
			},
			{
				productgroup: '11113', //for onclicktoitems
				name: 'Potten groot',
				image: potl,
				'names': {
					'FRA': 'Pot L ',
					'DEU': 'Topf L ',
					'NLD': 'Pot L ',
					'ENU': 'Pot L '
				}
			},
			{
				productgroup: '22221', //for onclicktoitems
				name: 'Vazen klein',
				image: vaass,
				'names': {
					'FRA': 'Vase S ',
					'DEU': 'Vase S ',
					'NLD': 'Vaas S ',
					'ENU': 'Vase S '
				}
			},
			{
				productgroup: '22222', //for onclicktoitems
				name: 'Vazen medium',
				image: vaasm,
				'names': {
					'FRA': 'Vase M ',
					'DEU': 'Vase M ',
					'NLD': 'Vaas M ',
					'ENU': 'Vase M '
				}
			},
			{
				productgroup: '22223', //for onclicktoitems
				name: 'Vazen groot',
				image: vaasl,
				'names': {
					'FRA': 'Vase L ',
					'DEU': 'Vase L ',
					'NLD': 'Vaas L ',
					'ENU': 'Vase L '
				}
			}
		]
	},
	{
		category: '12',
		productgroupsitem: [

			{
				productgroup: '11015', //for onclicktoitems
				name: '',
				image: imageUrlConstructor('2018-VK/christmas-pot.jpg'),
				'names': {
					'FRA': 'Christmas pots',
					'DEU': 'Christmas pots',
					'NLD': 'Christmas potten',
					'ENU': 'Christmas pots'
				}
			},
			{
				productgroup: '11020', //for onclicktoitems
				name: 'Christmas Decoration',
				image: imageUrlConstructor('2018-VK/christmas-decoration.jpg'),
				'names': {
					'FRA': 'Christmas Decoration',
					'DEU': 'Christmas Decoration',
					'NLD': 'Christmas Decoratie',
					'ENU': 'Christmas Decoration'
				}
			},
			{
				productgroup: '11030', //for onclicktoitems
				name: '	Christmas Chain',
				image: imageUrlConstructor('2018-VK/christmas-chain.jpg'),
				'names': {
					'FRA': 'Christmas Chain',
					'DEU': 'Christmas Chain',
					'NLD': 'Christmas Chain',
					'ENU': 'Christmas Chain'
				}
			},
			{
				productgroup: '11035', //for onclicktoitems
				name: 'Christmas Candle Holder',
				image: imageUrlConstructor('2018-VK/christmas-candleholder.jpg'),
				'names': {
					'FRA': 'Christmas Candle Holder',
					'DEU': 'Christmas Candle Holder',
					'NLD': 'Christmas Kaarsenhouders',
					'ENU': 'Christmas Candle Holder'
				}
			},
			{
				productgroup: '11050', //for onclicktoitems
				name: 'Christmas Ball/Ornament glass',
				image: imageUrlConstructor('2018-VK/christmas-ballglass.jpg'),
				'names': {
					'FRA': 'Christmas Ball/Ornament glass',
					'DEU': 'Christmas Ball/Ornament glass',
					'NLD': 'Christmas Glazen ballen en objecten',
					'ENU': 'Christmas Ball/Ornament glass'
				}
			},
			{
				productgroup: '11055', //for onclicktoitems
				name: 'Christmas Ball/Ornament NOT from glass',
				image: imageUrlConstructor('2018-VK/christmas-ballnotglass.jpg'),
				'names': {
					'FRA': 'Christmas Ball/Ornament NOT from glass',
					'DEU': 'Christmas Ball/Ornament NOT from glass',
					'NLD': 'Christmas Ballen en objecten niet van glas',
					'ENU': 'Christmas Ball/Ornament NOT from glass'
				}
			},
			{
				productgroup: '11060', //for onclicktoitems
				name: 'Christmas Statue (deers,trees,angels)',
				image: imageUrlConstructor('2018-VK/christmas-statue.jpg'),
				'names': {
					'FRA': 'Christmas Statue (deers,trees,angels)',
					'DEU': 'Christmas Statue (deers,trees,angels)',
					'NLD': 'Christmas Beelden',
					'ENU': 'Christmas Statue (deers,trees,angels)'
				}
			},
			{
				productgroup: '11070', //for onclicktoitems
				name: 'Christmas Tealight',
				image: imageUrlConstructor('2018-VK/christmas-tealight.jpg'),
				'names': {
					'FRA': 'Christmas Tealight',
					'DEU': 'Christmas Tealight',
					'NLD': 'Christmas Theelichtjes',
					'ENU': 'Christmas Tealight'
				}
			},
			{
				productgroup: '11075', //for onclicktoitems
				name: 'Christmas Stormlight',
				image: imageUrlConstructor('2018-VK/christmas-stormlight.jpg'),
				'names': {
					'FRA': 'Christmas Stormlight',
					'DEU': 'Christmas Stormlight',
					'NLD': 'Kerstlichtjes',
					'ENU': 'Christmas Stormlight'
				}
			}
		]
	},
	{
		category: '7',
		productgroupsitem: [

			{
				productgroup: '10065', //for onclicktoitems
				name: 'Plaids',
				image: plaids,
				'names': {
					'FRA': 'Plaids',
					'DEU': 'Plaids',
					'NLD': 'Plaids',
					'ENU': 'Plaids'
				}
			},
			{

				productgroup: '10010', //for onclicktoitems
				name: 'Kussenhoezen',
				image: kussenhoezen,
				'names': {
					'FRA': 'Housse de Coussin',
					'DEU': 'Kissenhülen',
					'NLD': 'Kussenhoezen',
					'ENU': 'Pillowcovers'
				}
			},
			{
				productgroup: '10080', //for onclicktoitems
				name: 'Vloerkleden',
				image: vloerkleden,
				'names': {
					'FRA': 'Carpette',
					'DEU': 'Teppiche',
					'NLD': 'Vloerkleden',
					'ENU': 'Carpets'
				}
			}
		]
	},
	{
		category: '6',
		productgroupsitem: [

			{
				productgroup: '18040', //for onclicktoitems
				name: 'Kunstplanten',
				image: kunstplanten,
				'names': {
					'FRA': 'Artificial Flower & Plants',
					'DEU': 'Artificial Flower & Plants',
					'NLD': 'Kunstplanten',
					'ENU': 'Artificial Flower & Plants'
				}
			}
		]
	},
	{
		category: '1000',
		productgroupsitem: [

			{
				productgroup: '1000', //for onclicktoitems
				name: 'X-mas',
				image: kunstplanten,
				'names': {
					'FRA': 'X-mas',
					'DEU': 'X-mas',
					'NLD': 'X-mas',
					'ENU': 'X-mas'
				}
			}
		]
	},
	{
		category: '4',
		productgroupsitem: [
			{

				productgroup: '16010', //for onclicktoitems
				name: 'Servies',
				image: servies,
				'names': {
					'FRA': 'Service de table',
					'DEU': 'Tafelgeschirr',
					'NLD': 'Servies',
					'ENU': 'Crockery'
				}
			},
			{
				productgroup: '16020', //for onclicktoitems
				name: 'Glaswerk',
				image: glaswerk,
				'names': {
					'FRA': 'Verrerie',
					'DEU': 'Glaswaren',
					'NLD': 'Glaswerk',
					'ENU': 'Glassware'
				}
			},
			{
				productgroup: '04010', //for onclicktoitems
				name: 'Dienbladen',
				image: dienbladen,
				'names': {
					'FRA': 'Plateaux',
					'DEU': 'Tabletts',
					'NLD': 'Dienbladen',
					'ENU': 'Trays'
				}
			}
		]
	},
	{
		category: '1',
		productgroupsitem: [

			{
				productgroup: '12040', //for onclicktoitems
				name: 'Kasten',
				image: kasten,
				'names': {
					'FRA': 'Armoires',
					'DEU': 'Schränke',
					'NLD': 'Kasten',
					'ENU': 'Cabinets'
				}
			},
			//currently contains 0 object
			// {

			// 	productgroup: '12050', //for onclicktoitems
			// 	name: 'Tuinmeubelen',
			// 	image: tuinmeubelen,
			// 	"names": {
			// 		"FRA": "Meubles de jardin",
			// 		"DEU": "Gartenmöbel",
			// 		"NLD": "Tuinmeubelen",
			// 		"ENU": "Gardenfurniture"
			// 	}
			// },
			// {

			// 	productgroup: '12010', //for onclicktoitems
			// 	name: 'Tafels',
			// 	image: tafels,
			// 	'names': {
			// 		'FRA': 'Tables',
			// 		'DEU': 'Tische',
			// 		'NLD': 'Tafels',
			// 		'ENU': 'Tables'
			// 	}
			// },
			//currently contains 0 object
			{
				productgroup: '12011', //for onclicktoitems
				name: 'Eettafels',
				image: eettafels,
				'names': {
					'FRA': 'Eettafels',
					'DEU': 'Esstisch',
					'NLD': 'Eettafels',
					'ENU': 'Eettafels'
				}
			},
			{
				productgroup: '12012', //for onclicktoitems
				name: 'Koffietafels',
				image: koffietafels,
				'names': {
					'FRA': 'Koffietafels',
					'DEU': 'Kaffeetisch',
					'NLD': 'Koffietafels',
					'ENU': 'Koffietafels'
				}
			},
			{
				productgroup: '12013', //for onclicktoitems
				name: 'Bijzettafels',
				image: bijzettafels,
				'names': {
					'FRA': 'Bijzettafels',
					'DEU': 'Beistelltisch',
					'NLD': 'Bijzettafels',
					'ENU': 'Bijzettafels'
				}
			},
			{
				productgroup: '12020', //for onclicktoitems
				name: 'Stoelen',
				image: stoelen,
				'names': {
					'FRA': 'Sitze',
					'DEU': 'Sitze',
					'NLD': 'Stoelen',
					'ENU': 'Sitze'
				}
			},
			{
				productgroup: '12025', //for onclicktoitems
				name: 'Fauteauil',
				image: fauteauil,
				'names': {
					'FRA': 'Sessel',
					'DEU': 'Sessel',
					'NLD': 'Fauteauil',
					'ENU': 'Sessel'
				}
			},
			{
				productgroup: '12014', //for onclicktoitems
				name: 'Barkruk',
				image: barkruk,
				'names': {
					'FRA': 'Barhocker',
					'DEU': 'Barhocker',
					'NLD': 'Barkruk',
					'ENU': 'Barhocker'
				}
			},
			{
				productgroup: '12016', //for onclicktoitems
				name: 'Poefs',
				image: poefs,
				'names': {
					'FRA': 'Poefs',
					'DEU': 'Poefs',
					'NLD': 'Poefs',
					'ENU': 'Poefs'
				}
			},
			{
				productgroup: '12030', //for onclicktoitems
				name: 'Non Elementen banken',
				image: neb,
				'names': {
					'FRA': 'Nicht-Element-Banken',
					'DEU': 'Nicht-Element-Banken',
					'NLD': 'Non Elementen banken',
					'ENU': 'Nicht-Element-Banken'
				}
			},
			{
				productgroup: '12035', //for onclicktoitems
				name: 'Sofa element Block',
				image: eb,
				'names': {
					'FRA': 'Element-Banken',
					'DEU': 'Element-Banken',
					'NLD': 'Elementen banken',
					'ENU': 'Element-Banken'
				}
			},
			{
				productgroup: '12015', //for onclicktoitems
				name: 'Krukjes',
				image: krukjes,
				'names': {
					'FRA': 'Tabourets',
					'DEU': 'Hocker',
					'NLD': 'Krukjes',
					'ENU': 'Stools'
				}
			}
		]
	},
	{
		category: '5',
		productgroupsitem: [

			{
				productgroup: '08100', //for onclicktoitems
				name: 'Deco wandpaneel',
				image: decowandpaneel,
				'names': {
					'FRA': 'Panneau mural',
					'DEU': 'Deko Wandplatte',
					'NLD': 'Deco wandpaneel',
					'ENU': 'Wall panel'
				}
			},
			{

				productgroup: '05010', //for onclicktoitems
				name: 'Wandhaken',
				image: wandhaken,
				'names': {
					'FRA': 'Crochets mureaux',
					'DEU': 'Mauerhaken',
					'NLD': 'Wandhaken',
					'ENU': 'Wallhooks'
				}
			},
			{
				productgroup: '08035', //for onclicktoitems
				name: 'Klokken',
				image: klokken,
				'names': {
					'FRA': 'Cloches',
					'DEU': 'Uhren',
					'NLD': 'Klokken',
					'ENU': 'Clocks'
				}
			},
			{
				productgroup: '17010', //for onclicktoitems
				name: 'Spiegel',
				image: spiegel,
				'names': {
					'FRA': 'Miroirs',
					'DEU': 'Spiegel',
					'NLD': 'Spiegels',
					'ENU': 'Mirrors'
				}
			},
			{
				productgroup: '08105', //for onclicktoitems
				name: 'Schilderijen',
				image: schilderijen,
				'names': {
					'FRA': 'Tableaux',
					'DEU': 'Bilder',
					'NLD': 'Schilderijen',
					'ENU': 'Paintings'
				}
			},
			{
				productgroup: '20000', //for onclicktoitems
				name: 'Verf',
				image: verf,
				'names': {
					'FRA': 'Peinture',
					'DEU': 'Farben',
					'NLD': 'Verf',
					'ENU': 'Paint'
				}
			}
		]
	},
	{
		category: '11',
		productgroupsitem: [

			{
				productgroup: '21011', //for onclicktoitems
				name: 'Hanglampen',
				image: hanglampen,
				'names': {
					'FRA': 'Lustres',
					'DEU': 'Hängelampen',
					'NLD': 'Hanglampen',
					'ENU': 'Hanging lamp'
				}
			},
			{

				productgroup: '21014', //for onclicktoitems
				name: 'Wandlampen',
				image: wandlampen,
				'names': {
					'FRA': 'Appliques murales',
					'DEU': 'Wandlampen',
					'NLD': 'Wandlampen',
					'ENU': 'Wall lamps'
				}
			},
			{
				productgroup: '21012', //for onclicktoitems
				name: 'Staande lampen',
				image: staandelampen,
				'names': {
					'FRA': 'Lampadaires',
					'DEU': 'Stehlampen',
					'NLD': 'Staande lampen',
					'ENU': 'Floor lamps'
				}
			},
			{
				productgroup: '21013', //for onclicktoitems
				name: 'Tafellampen',
				image: tafellampen,
				'names': {
					'FRA': 'Lampes de table',
					'DEU': 'Tischlampen',
					'NLD': 'Tafellampen',
					'ENU': 'Table lamp'
				}
			},
			{
				productgroup: '21020', //for onclicktoitems
				name: 'Lampenkappen',
				image: lampenkappen,
				'names': {
					'FRA': 'Abats jours',
					'DEU': 'Lampenschirme',
					'NLD': 'Lampenkappen',
					'ENU': 'Lampshades'
				}
			}
		]
	},
	{
		category: '10',
		productgroupsitem: [

			{
				productgroup: '09080', //for onclicktoitems
				name: 'Windlichten',
				image: Windlichten,
				'names': {
					'FRA': 'Bougeoirs en verre',
					'DEU': 'Windlichter',
					'NLD': 'Windlichten',
					'ENU': 'Stormlights'
				}
			},
			{

				productgroup: '13010', //for onclicktoitems
				name: 'Standbeelden',
				image: Standbeelden,
				'names': {
					'FRA': 'Statues',
					'DEU': 'Statuen',
					'NLD': 'Standbeelden',
					'ENU': 'Statues'
				}
			},
			{
				productgroup: '02020', //for onclicktoitems
				name: 'Manden',
				image: Manden,
				'names': {
					'FRA': 'Paniers',
					'DEU': 'Körbe',
					'NLD': 'Manden',
					'ENU': 'Baskets'
				}
			},
			{
				productgroup: '14010', //for onclicktoitems
				name: 'Fotolijsten',
				image: Fotolijsten,
				'names': {
					'FRA': 'Cadres photos',
					'DEU': 'Bilderrahmen',
					'NLD': 'Fotolijsten',
					'ENU': 'Photo frames'
				}
			},
			{
				productgroup: '01115', //for onclicktoitems
				name: 'Stolp',
				image: Stolp,
				'names': {
					'FRA': 'Cloche de Verre',
					'DEU': 'Glasglocke',
					'NLD': 'Stolp',
					'ENU': 'Bell Jar'
				}
			},
			{

				productgroup: '04110', //for onclicktoitems
				name: 'Etagere',
				image: Etagere,
				'names': {
					'FRA': 'Étagère',
					'DEU': 'Etagère',
					'NLD': 'Etagère',
					'ENU': 'Whatnot'
				}
			},
			// {
			// 	productgroup: '16030', //for onclicktoitems
			// 	name: 'Wijnkoelers',
			// 	image: Wijnkoelers,
			// 	'names': {
			// 		'FRA': 'refroidisseurs à vin',
			// 		'DEU': 'Weinkühler',
			// 		'NLD': 'wijnkoelers',
			// 		'ENU': 'Wine coolers'
			// 	}
			// },
			{
				productgroup: '08030', //for onclicktoitems
				name: 'Opbergen',
				image: Opbergen,
				'names': {
					'FRA': 'Ranger',
					'DEU': 'Bergen',
					'NLD': 'Opbergen',
					'ENU': 'Storage'
				}
			},
			{
				productgroup: '08150', //for onclicktoitems
				name: 'Decoratie hanger',
				image: Decoratiehanger,
				'names': {
					'FRA': 'Chaînette Décoration',
					'DEU': 'Dékoration Hänger',
					'NLD': 'Decoratie hanger',
					'ENU': 'Hanging Decoration'
				}
			}
		]
	}
];
