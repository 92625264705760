import React from 'react';
import '../styles/suggestionCard.css';
import Typewriter from 'typewriter-effect';


export default props => {
    const {text, typist, keys} = props;
	return <div className='noSuggestions'>
		{
			typist ?
            <>
		    <span className='text'>{text}</span>
            {
            keys === 'sku' ? 
            <Typewriter
                options={{
                    strings: ['692643', '692000', '692179'],
                    pauseFor: 400,
                    autoStart: true,
                    loop: true
                }}
            /> :
            <div>
            <Typewriter
                options={{
                    strings: ['Kerst', 'Meubels', 'Pot', 'green'],
                    pauseFor: 400,
                    autoStart: true,
                    loop: true
                }}
            />
            </div>
            }
            </> :
		    <span className='text'>{text}</span>
		}
	</div>;
};