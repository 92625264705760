import React from 'react';

export const renderPinFunction = (actionFunction, removeLastDigitFunction, handleNextHTML, pinView) => {
	return (
		<div className="container py-4">
			<div className="row">
				<div className="col-auto mx-auto bg-white rounded shadow">
					<div className="btn-group-vertical mx-4 my-5" role="group" aria-label="Basic example">
						{pinView}
						<div className="btn-group btn-group-lg">
							<button type="button" className="btn btn-outline-secondary border-bottom-0 rounded-0" onClick={() => actionFunction('1')}>1</button>
							<button type="button" className="btn btn-outline-secondary border-bottom-0" onClick={() => actionFunction('2')}>2</button>
							<button type="button" className="btn btn-outline-secondary border-bottom-0 rounded-0" onClick={() => actionFunction('3')}>3</button>
						</div>
						<div className="btn-group btn-group-lg">
							<button type="button" className="btn btn-outline-secondary border-bottom-0 rounded-0" onClick={() => actionFunction('4')}>4</button>
							<button type="button" className="btn btn-outline-secondary border-bottom-0" onClick={() => actionFunction('5')}>5</button>
							<button type="button" className="btn btn-outline-secondary border-bottom-0 rounded-0" onClick={() => actionFunction('6')}>6</button>
						</div>
						<div className="btn-group btn-group-lg">
							<button type="button" className="btn btn-outline-secondary rounded-0" onClick={() => actionFunction('7')}>7</button>
							<button type="button" className="btn btn-outline-secondary" onClick={() => actionFunction('8')}>8</button>
							<button type="button" className="btn btn-outline-secondary rounded-0" onClick={() => actionFunction('9')}>9</button>
						</div>
						<div className="btn-group btn-group-lg">
							<button type="button" className="btn btn-outline-secondary" onClick={() => actionFunction('0')}>0</button>
						</div>
						<div className="btn-group btn-group-lg">
							<button type="button" onClick={removeLastDigitFunction} className="btn btn-outline-secondary rounded-0"><span className="small"><i className="fa fa-arrow-circle-left" aria-hidden="true"></i></span></button>
							{handleNextHTML}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};