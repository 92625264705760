export const setItems = (payload) => {
	return {
		type: 'SETITEMS',
		payload
	};
};

export const reset = () => {
	return {
		type: 'RESET'
	};
};

export const setPageTitle = (pageTitle) => {
	return {
		type: 'SETPAGETITLE',
		payload: pageTitle
	};
};

export const setGenerals = (generals, _id) => {
	return {
		type: 'SETGENERALS',
		payload: { generals, _id }
	};
};

export const setWishListLength = (wishlistLength) => {
	return {
		type: 'SETWISHLISTLENGTH',
		payload: wishlistLength
	};
};

export const setWishListTP = (TP) => {
	return {
		type: 'SETWISHLISTTP',
		payload: TP
	};
};

export const setCrumbles = (crumbles) => {
	return {
		type: 'SETCRUMBLES',
		payload: crumbles
	};
};

export const setId = (id) => {
	return {
		type: 'SETID',
		payload: id
	};
};

export const openKeyboard = (bool) => {
	return {
		type: 'OPENKEYBOARD',
		payload: bool
	};
};

export const setKeyboardText = (text) => {
	return {
		type: 'SETKEYBOARDTEXT',
		payload: text
	};
};

export const setGeneralFacets = (facets) => {
	return {
		type: 'SETGENERALFACETS',
		payload: facets
	};
};

export const toggleETA = (on) => {
	return {
		type: 'TOGGLEETA',
		payload: on
	};
};