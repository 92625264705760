import React, { useEffect, useState } from 'react';
import Checkout from '../views/checkout';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import withPage from '../hoc/withPage';
import { useDispatch } from 'react-redux';
import { getWishlist } from '../api/wishlist';
import { makeOrder } from './../api/order';
import '../styles/checkout.css';
import { getBasicData } from './../api/general';
import { setGenerals } from '../actions/actions';
import { useTranslation } from 'react-i18next';

const CheckoutContainer = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [wishlist, setWishlist] = useState();
	const [totalPrice, setTotalPrice] = useState();
	const {zuilid} = useParams();
	const order = async (pin, name) => {
		if (pin && name && pin.length && name.length && zuilid) {
			await makeOrder(zuilid, name, pin).then(() => {
				history.push(`/${zuilid}`);
			});
			getBasicData(zuilid).then(g => {
				dispatch(setGenerals(g, zuilid));
			});
		}
	};
	const items = (acc, wishlistItem) => {
		const { amount } = wishlistItem;
		const wishlistItemParsed = JSON.parse(wishlistItem.wishlistItem);
		return acc + parseFloat(wishlistItemParsed.price) * amount;
	};
	useEffect(() => {
		getWishlist(zuilid).then(w => {
			setWishlist(w.wishlist);
			setTotalPrice(w.wishlist.reduce(items, 0));
		});
	}, []);
	return (
		<Checkout
			wishlist={wishlist}
			totalPrice={totalPrice}
			order={order}
			t={t}
			i18n={i18n}
		/>
	);
};

export default withRouter(withPage({title: 'checkout'})(CheckoutContainer));