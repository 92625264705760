import React, { useState } from 'react';
import Header from '../views/header';
import '../styles/header.css';
import FabContainer from './fab';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import SearchContainer from './search';
import 'react-simple-keyboard/build/css/index.css';
import swal from 'sweetalert2';
import { sendFeedback as SF, toggleEta } from './../api/general';
import { useSelector, useDispatch } from 'react-redux';
import { toggleETA } from '../actions/actions';

const HeaderContainer = (props) => {
	const history = useHistory();
	const { zuilid } = useParams();
	const [showFab, setShowFab] = useState(true);
	const [modalOpen, openModal] = useState(false);
	const dispatch = useDispatch();

	const {
		etaEnabled
	} = useSelector(
		state => ({
			etaEnabled: state.main.etaEnabled
		})
	);

	const doToggleEta = () => {
		toggleEta(zuilid, !etaEnabled);
		dispatch(toggleETA(!etaEnabled));
	};

	const toggleModal = () => {
		openModal(!modalOpen);
	}

	const sendFeedback = (feedback) => {
		if (feedback.length) {
			// api send feedback
			SF(feedback);
			swal.fire("Bedankt", "We zullen je feedback behandelen.", "success");
			openModal(false);
		}
	}

	const goToHome = () => {
		history.push(`/${zuilid}`);
	};
	return (
		<div className='headerContainer fixed-top'>
			<div className='backgroundWhite'>
				<div className='top'>
					<Header
						handleCloseModal={toggleModal}
						modalOpen={modalOpen}
						sendFeedback={sendFeedback}
						openModal={toggleModal}
						goToHome={goToHome}
						zuilid={zuilid}
						toggleEta={doToggleEta}
						etaEnabled={etaEnabled}
						showAnalytics={zuilid === 'initworks' || window.location.href.includes('beta')}
					/>
				</div>
				<div className='searchbar'>
					<SearchContainer setShowFab={setShowFab} />
				</div>
			</div>
			<div className='fab'>
				{showFab && <FabContainer />}
			</div>
		</div>
	);
};

export default withRouter(HeaderContainer);