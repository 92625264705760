import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../styles/general.css';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle, setGenerals, setCrumbles, setId } from '../actions/actions';
import { getBasicData, getGeneralFacets } from './../api/general';
import { useParams } from 'react-router-dom';
import { PRODUCTGROUPS } from './../hardcodedFiles/productgroups';
import { CATEGORIES } from './../hardcodedFiles/categories';
import { translate } from './../hardcodedFiles/translationHelper';
import { setGeneralFacets } from './../actions/actions';
import i18n from 'i18next';

export default page => ComposedComponent => props => {
	const WithPage = () => {
		// const { i18n } = useTranslation();
		const dispatch = useDispatch();
		const [shouldFetch, setShouldFetch] = useState(true);
		const p = useParams();
		const {
			retrievedBasics
		} = useSelector(
			state => ({
				retrievedBasics: state.main.retrievedBasics
			})
		);
		const match = useParams();
		if (shouldFetch) {
			let categoryFacet;
			let productgroupFacet;
			dispatch(setPageTitle(page.title));
			if (['category'].includes(page.title)) {
				getBasicData(p.zuilid).then(g => {
					if (g.language) i18n.changeLanguage(g.language);
					dispatch(setGenerals(g, p.zuilid));
				});
			}
			if (['category', 'productgroup', 'browse', 'item'].includes(page.title)) {
				const { category, productgroup, sku } = match;
				let ct;
				let pt;
				let ctsolr;
				const findCat = CATEGORIES.find(c => +c.code === +category);
				let findProdObject;
				let findProd;
				if (findCat) {
					ctsolr = translate(findCat.names, 'nl');
					ct = translate(findCat.names, i18n.language || window.localStorage.i18nextLng);
					findProdObject = PRODUCTGROUPS.find(pr => +pr.category === +category).productgroupsitem;
				}
				if (findProdObject) {
					findProd = findProdObject.find(pro => +pro.productgroup === +productgroup);
				}
				if (findProd) {
					pt = translate(findProd.names, i18n.language || window.localStorage.i18nextLng);
				}
				categoryFacet = ctsolr;
				productgroupFacet = productgroup;
				dispatch(setCrumbles({
					category, ct,
					productgroup, pt, sku
				}));
			}
			if (['category', 'browsefound', 'productgroup', 'browse', 'item', 'wishlist', 'checkout'].includes(page.title) && !retrievedBasics) {
				dispatch(setId(p.zuilid));
				getBasicData(p.zuilid).then(g => {
					dispatch(setGenerals(g, p.zuilid));
				});
			}
			dispatch(setGeneralFacets());
			getGeneralFacets(p.zuilid, { categoryFacet, productgroupFacet }).then(data => dispatch(setGeneralFacets(data)));

			setShouldFetch(false);
		}

		return <motion.div
			initial={{
				opacity: 0,
				y: 50
			}}
			animate={{
				opacity: 1,
				y: 0
			}}
		>
			<Container fluid className={`text-center ${!['item'].includes(page.title) ? 'withPage' : 'withPageItem'} general`}>
				<Row>
					<ComposedComponent {...props} />
				</Row>
			</Container>
		</motion.div>;
	};
	return <WithPage />;
};