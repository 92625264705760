/* eslint-disable no-magic-numbers */
import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
export default props => {
	const { currentpage, maxPageNumber, updateCurrentPage } = props;

	const basePaginatorObject = (e) => {
		if (e > 0 && e < maxPageNumber + 1 ) {
			return <Pagination.Item onClick={()=>updateCurrentPage(e)}>{e}</Pagination.Item>;
		}
	};
	const basePaginatorObjectCenter = (e) => {
		if (e > 0) {
			return <Pagination.Item active>{e}</Pagination.Item>;
		}
	};
	const basePaginatorObjectFirstOrLast = (e) => {
		if (currentpage > e && currentpage !== 1 && currentpage - 2 > 1) {
			return <Pagination.Item id='paginationFirst' onClick={()=>updateCurrentPage(e)}>{e}</Pagination.Item>;
		}
		if (currentpage < e && currentpage !== maxPageNumber && currentpage + 2 < maxPageNumber) {
			return <Pagination.Item id='paginationLast' onClick={()=>updateCurrentPage(e)}>{e}</Pagination.Item>;
		}
	};
	return (
		<Pagination>
			{basePaginatorObjectFirstOrLast(1)}
			{/* {basePaginatorObject(currentpage - 2)} */}
			{basePaginatorObject(currentpage - 1)}
			{basePaginatorObjectCenter(currentpage)}
			{basePaginatorObject(currentpage + 1)}
			{/* {basePaginatorObject(currentpage + 2)} */}
			{basePaginatorObjectFirstOrLast(maxPageNumber)}
		</Pagination>
	);
};
