import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { isItemPartOfLongDelivery, isItemBlockSofa } from '../api/items';
import { motion } from 'framer-motion';
import NumberFormat from 'react-number-format';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { formatEta } from './../hardcodedFiles/helpers';

const Item = props => {
	const { item, goToSuggestion, addToWishlist, t, etaEnabled } = props;
	const [images, setImages] = useState([]);

	useEffect(() => {
		if (item && images.length === 0) {
			console.log(item)
			const { imageMedium } = item;
			const imgs = [
				'moodImage1Large',
				'moodImage2Large',
				'moodImage3Large',
				'detailImageLarge',
				'frontImageLarge',
				'backImageLarge',
				'sideImageLarge',
				'topImageLarge',
				'otherImageLarge'
			].map(i => {
				return item[i];
			}).filter(a => a);
			imgs.unshift(imageMedium);
			setImages(imgs);
		}
	}, [item]);

	const motionDiv = (i) => {
		return <motion.div whileTap={{
			scale: 1.3,
			backgroundColor: 'lightgrey',
			transition: { duration: 0.2 }
		}} className="one-third no-border text-right black" onClick={() => addToWishlist(i)}>
			<div className='fav-icon-holder'>
				<i className="fas fa-cart-plus"></i>
			</div>
		</motion.div>;
	};
	const quantityPerSold = () => {
		if (item && item.categoryCode === 1 && item.innerBoxQuantity > 1) {
			return <span className='units'>{t('Thisitemis')} {item.innerBoxQuantity} {t('sold')}</span>
		}
	};
	const showEta = () => {
		if (item && item.eta && etaEnabled) {
			return <span className='units'>{formatEta(item.eta, t)}</span>;
		};
	};
	const showAvailableShortly = () => {
		if (item && item.eta && item.stock !== undefined && item.stock < 1) {
			return <span className='units'>Binnenkort verkrijgbaar</span>;
		};
	};
	const RenderItem = () => {
		const { sku, imageMedium, price, size, stock, collection } = item;
		const RenderSuggestions = () => {
			return item.suggestions.map((i, index) => {
				const { imageSmall, sku: s, price: p, size: si } = i;
				return (
					<div
						className='scrolling-wrapper-card text-center'
						key={s + index}
						onClick={() => { goToSuggestion(s); }}
					>
						<img alt={imageSmall} src={imageSmall} style={{ width: '110px', marginBottom: '30px' }}></img>
						<br />
						<div className='clash-card__unit-name-item-s'>{si}</div>
						<div className='clash-card__unit-name-item-s'>
							<NumberFormat value={p} displayType={'text'} thousandSeparator={true} prefix={'€ '} />
						</div>
					</div >
				);
			});
		};
		return (
			<Col sm={12}>
				<div className="wrapper-item" >
					<div className="clash-card-item barbarian-item">
						<div className="clash-card__image-item clash-card__image--barbarian-item">
							<div className="stat">{collection}</div>
							<hr className='hr' />

						</div>
						<div className='imageHolder'>
							{
								images.length > 1 ?
									<Carousel showThumbs={false} showStatus={false}>
										{
											images.map(i => {
												return <img key={i} src={i} alt={'dwd'} style={{ width: 'auto', maxHeight: '330px', borderRadius: '10px' }}></img>
											})
										}
									</Carousel>
									: <img src={images[0]} alt={'dwd'} style={{ width: 'auto', maxHeight: '330px' }}></img>
							}
						</div>
						<div className="clash-card__unit-stats-item clash-card__unit-stats--barbarian-item ">
							<div className="clash-card__unit-name-item">{item['description.ENU']}</div>
							{showAvailableShortly()}
							{quantityPerSold()}
							{showEta()}
							<div className='eWrapper'>
								<div className="price no-border text-left info">
									<div className="clash-card__level-item clash-card__level--barbarian-item"></div>
									<div className="clash-card__unit-description-item">
										<NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'€ '} />
									</div>
								</div>
								<div className="extra no-border text-left info">
									<div className="clash-card__level-item clash-card__level--barbarian-item"></div>
									<div className="clash-card__unit-description-item">
										{size} <br />Item Nr.: {sku} <br /> {t('stock')}: {stock} <br /> {isItemPartOfLongDelivery(item) && t(isItemBlockSofa(item))}
									</div>
								</div>
								<div className="one-third no-border text-right black" onClick={() => addToWishlist(item)}>
									<div className='fav-icon-holder'>
										<i className="fas fa-cart-plus"></i>
									</div>
								</div>
							</div>
							{
								item.suggestions && item.suggestions.length ? <div className="scrolling-wrapper">
									{RenderSuggestions()}
								</div> : null
							}
							{
								item.b2cDescription ?
									<div className='b2c-description-holder'>
										<span className='b2c-description-title'>Beschrijving</span>
										<hr className='b2c-description-hr' align='left' width='40px' />
										<div className='b2c-description'>{item.b2cDescription}</div>
									</div> : null
							}
						</div>
					</div>

				</div>
			</Col>
		);
	};
	return item ? RenderItem() : null;
};

export default Item;