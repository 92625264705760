import React, { useEffect, useState } from 'react';
import Wishlist from './../views/wishlist';
import withPage from '../hoc/withPage';
import { withRouter, useParams } from 'react-router-dom';
import { getWishlist, removeFromWishlist, wishlistItemMin, removeSofaFromWishlist } from '../api/wishlist';
import { wishlistItemPlus } from './../api/wishlist';
import '../styles/wishlist.css';
import { useDispatch, useSelector } from 'react-redux';
import { setWishListLength, setWishListTP } from '../actions/actions';
import { useTranslation } from 'react-i18next';

const WishlistContainer = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const {
		wishlistTP
	} = useSelector(
		state => ({
			wishlistTP: state.main.wishlistTP
		})
	);
	const [wishlist, setWishlist] = useState();
	const {zuilid} = useParams();
	const items = (acc, wishlistItem) => {
		const { amount } = wishlistItem;
		const wishlistItemParsed = JSON.parse(wishlistItem.wishlistItem);
		return acc + parseFloat(wishlistItemParsed.price) * amount;
	};
	const handleTotalCheckoutPrice = a => {
		dispatch(setWishListTP(a));
	};
	const updateWishlist = (list, amount) => {
		setWishlist(list);
		dispatch(setWishListLength(amount));
		handleTotalCheckoutPrice(list.reduce(items, 0));
	};
	const removeItem = sku => {
		removeFromWishlist(zuilid, sku).then(data => {
			updateWishlist(data.wishlist, data.amount);
		});
	};
	const removeSofaGroup = sofaGroup => {
		console.log(sofaGroup)
		removeSofaFromWishlist(zuilid, sofaGroup).then(data => {
			console.log(data)
			updateWishlist(data.wishlist, data.amount);
		});
	};
	const plusItem = sku => {
		wishlistItemPlus(zuilid, sku).then(data => {
			updateWishlist(data.wishlist, data.amount);
		});
	};
	const minItem = sku => {
		wishlistItemMin(zuilid, sku).then(data => {
			updateWishlist(data.wishlist, data.amount);
		});
	};
	useEffect(() => {
		getWishlist(zuilid).then(w => {
			setWishlist(w.wishlist);
			handleTotalCheckoutPrice(w.wishlist.reduce(items, 0));
		});
	}, []);
	return (
		<Wishlist
			wishlist={wishlist}
			removeSofaGroup={removeSofaGroup}
			removeItem={removeItem}
			plusItem={plusItem}
			minItem={minItem}
			wishlistTP={wishlistTP}
			t={t}
			i18n={i18n}
		/>
	);
};

export default withRouter(withPage({title: 'wishlist'})(WishlistContainer));
