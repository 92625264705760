export const externalLinks = [
    {
        type: 'blockbank',
        code: '12035',
        link: (baseUrl, zuilid) => `${baseUrl}${zuilid}`
    }
];

export const getCorrectBase = (url) => {
    if (url.includes('localhost')) {
        // return 'http://localhost:48888/';
        return 'https://configurator.beta.ptmd.nl/';
    } else if (url.includes('beta')) {
        return 'https://configurator.beta.ptmd.nl/';
    }
    return 'https://configurator.ptmd.nl/';
}