import React, { useEffect } from 'react';
import { getItems } from './../api/items';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, withRouter, useHistory } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import '../styles/browse.css';
import Browse from '../views/browse';
import { setItems } from './../actions/actions';
import withPage from '../hoc/withPage';
import { useTranslation } from 'react-i18next';

const BrowseContainer = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const {
		items
	} = useSelector(
		state => ({
			items: state.main.items
		})
	);
	const history = useHistory();
	const [page] = useQueryParam('page', StringParam);
	const [order] = useQueryParam('order', StringParam);
	const [sort] = useQueryParam('sort', StringParam);
	const [sortOption] = useQueryParam('sortOption', StringParam);
	const match = useRouteMatch({
		path: '/:zuilid/browse/:categoryId/:productGroupId'
	});
	const {zuilid, productGroupId} = match.params;
	const dispatchProductItemPage = sku => {
		history.push(`/${zuilid}/item/${sku}`);
	};
	useEffect(() => {
		if (sort) {
			dispatch({type: 'RESETITEMS'});
			getItems(page || 1, productGroupId, zuilid, {order, on: sort === 'true' ? true : false, option: sortOption}).then(i => {
				dispatch(setItems(i));
			});
		} else {
			dispatch({type: 'RESETITEMS'});
			getItems(page || 1, productGroupId, zuilid).then(i => {
				dispatch(setItems(i));
			});
		}
	}, []);
	return (
		<Browse
			items={items}
			dispatchProductItemPage={dispatchProductItemPage}
			t={t}
			i18n={i18n}
		/>
	);
};

export default withRouter(withPage({title: 'browse'})(BrowseContainer));