import moment from 'moment';
const etaMarginInDays = 7

export const formatEta = (eta, t, hidePreText) => {
    if (eta) {
        if (hidePreText) {
            return moment(new Date(eta)).add(etaMarginInDays, 'days').format("Do MMM YYYY");
        }
        return `${t('eta')} ${moment(new Date(eta)).add(etaMarginInDays, 'days').format("Do MMM YYYY")}`;
    }
}