import React from 'react';
import {Col} from 'react-bootstrap';
import { isItemPartOfLongDelivery } from '../api/items';
import { isItemBlockSofa } from './../api/items';
import NumberFormat from 'react-number-format';
const titleCollapseLength = 38;
const Browse = props => {
	const {items, dispatchProductItemPage, t} = props;
	return items ? items.map((item, index) => {
		const {sku, imageSmall, collection, size, price} = item;
		let description = item['description.ENU'];
		if (description.length > titleCollapseLength) {
			description = description.substring(0, titleCollapseLength).concat('...');
		}
		return (
			<Col onClick={(e) => { e.stopPropagation(); dispatchProductItemPage(sku); }} key={index} sm={4}>
				<div className="wrapper">
					<div className="clash-card barbarian">
						<div className="clash-card__image clash-card__image--barbarian">
							<img src={imageSmall} alt={index} style={{}}></img>
						</div>
						<div className="clash-card__level clash-card__level--barbarian"><NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'€ '} /></div>
						<div className="clash-card__unit-name">{description}</div>
						<div className="clash-card__unit-description">
							{size} <br /> {sku} {isItemPartOfLongDelivery(item) && <span> -  {t(isItemBlockSofa(item))} </span>}
						</div>
						<div className="clash-card__unit-stats clash-card__unit-stats--barbarian ">
							<div className="one-third no-border">
								<div className="stat">{collection}</div>
							</div>
						</div>

					</div>
				</div>
			</Col>
		);
	}) : null;
};

export default Browse;