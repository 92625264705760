import React, { useEffect } from 'react';
import { withRouter, useHistory, useRouteMatch } from 'react-router-dom';
import ProductGroup from '../views/productGroup';
import '../styles/category.css';
import { PRODUCTGROUPS } from './../hardcodedFiles/productgroups';
import { useDispatch } from 'react-redux';
import { reset } from '../actions/actions';
import withPage from '../hoc/withPage';
import { useTranslation } from 'react-i18next';
import { getCorrectBase } from '../hardcodedFiles/external';
const ProductGroupContainer = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const match = useRouteMatch({
		path: '/:zuilid/browse/:categoryId'
	});
	const dispatch = useDispatch();
	const getProductgroups = (categoryId) => {
		return PRODUCTGROUPS.find(pg => pg.category === categoryId);
	};
	const dispatchProductGroupCode = (productGroupCode) => {
		const {zuilid, categoryId} = match.params;
		const findProd = getProductgroups(match.params.categoryId).productgroupsitem.find(p => +p.productgroup === +productGroupCode);
		history.push(`/${zuilid}/browse/${categoryId}/${productGroupCode}?searchQuery=${JSON.stringify([{pageType: 'productgroup', value: findProd ? findProd.names.NLD : undefined, code: findProd ? findProd.productgroup : undefined}])}` );
	};
	const goToCustom = (path) => {
		const {zuilid} = match.params;
		console.log(window.location)
		return window.location = `${path(getCorrectBase(window.location.href), zuilid)}?returnURL=${window.location.host}/${zuilid}`;
	};
	useEffect(() => {
		dispatch(reset());
	}, []);
	return (
		<ProductGroup
			data={getProductgroups(match.params.categoryId).productgroupsitem}
			dispatchProductGroupCode={dispatchProductGroupCode}
			goToCustom={goToCustom}
			t={t}
			i18n={i18n}
		/>
	);
};

export default withRouter(withPage({title: 'productgroup'})(ProductGroupContainer));