import React, { useState } from 'react';
import logo from '../resources/images/DesignedGold.png';
import gan from '../resources/images/gan.png';
import designedWithPassion from '../resources/images/Designed.png';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import 'font-awesome/css/font-awesome.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Fab, Button } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const Header = props => {
	const { goToHome, showAnalytics, modalOpen, handleCloseModal, openModal, sendFeedback, etaEnabled, toggleEta } = props;
	const [feedback, setFeedback] = useState('');
	const [settingsOpen, openSettings] = useState(false);

	const closeSettings = () => {
		openSettings(false);
	};

	const doOpenSettings = () => {
		openSettings(true);
	};

	return (
		<>
			<Modal
				open={modalOpen}
				onClose={handleCloseModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div className='feedbackModal'>
					<span className='feedbackModalTitle'>Laat hier je feedback achter.</span>
					<div className='feedbackModalBody'>
						<TextField
							id="standard-multiline-static"
							label="Feedback..."
							variant='outlined'
							value={feedback}
							onChange={e => setFeedback(e.target.value)}
							autoFocus
							fullWidth
							multiline
							rows={4}
						/>
						<Button variant="contained" color="primary" onClick={() => { sendFeedback(feedback); setFeedback('') }}>Versturen</Button>
					</div>
				</div>
			</Modal>
			<Modal
				open={settingsOpen}
				onClose={closeSettings}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div className='settingsModal'>
					<span className='settingsModalTitle'><i className="fas fa-cog" /> Instellingen</span>
					<div className='settingsModalBody'>
						<FormControlLabel
							control={<Switch checked={etaEnabled} onChange={toggleEta} name="checkedA" />}
							label="Toggle ETA"
						/>
					</div>
				</div>
			</Modal>
			<Container fluid>
				<Row>
					<Col sm={4} md={4} className='text-left topCol'>
						<Fab className='homeFab'>
							<i className="fas fa-home" onClick={goToHome}></i>
						</Fab>
						<Fab className='infoFab'>
							<i className="fa fa-commenting-o" onClick={openModal}></i>
						</Fab>
						<Fab className='infoFab'>
							<i className="fas fa-cog" onClick={doOpenSettings}></i>
						</Fab>
					</Col>
					<Col className='text-center designedWithPassionLogo topCol' sm={4} md={4}>
						<img src={designedWithPassion} alt=""></img>
					</Col>
					<Col className='text-right logo topCol' sm={4} md={4}>
						{
							showAnalytics && <a href='https://analytics.google.com/analytics/web/?hl=nl#/report-home/a102066913w161865932p162934675' style={{ marginRight: '20px' }}><img src={gan} alt=""></img></a>
						}
						<img onClick={goToHome} src={logo} alt=""></img>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Header;