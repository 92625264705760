export const initialState = {
	items: undefined,
	numFound: undefined,
	start: undefined,
	pageTitle: 'category',
	wishlistLength: undefined,
	language: 'en',
	name: undefined,
	retrievedBasics: false,
	_id: undefined,
	wishlistTP: 0,
	crumbles: undefined,
	keyboardOpen: false,
	keyboardText: '',
	generalFacets: undefined,
	etaEnabled: false,
};
export const mainreducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SETITEMS':
			return {
				...state,
				items: action.payload.docs,
				numFound: action.payload.numFound,
				start: action.payload.start
			};
		case 'RESETITEMS':
			return {
				...state,
				items: undefined,
				numFound: undefined,
				start: undefined
			};
		case 'SETPAGETITLE':
			return {
				...state,
				pageTitle: action.payload
			};
		case 'RESET':
			return {
				...state,
				items: undefined,
				numFound: undefined,
				start: undefined
			};
		case 'SETWISHLISTLENGTH':
			return {
				...state,
				wishlistLength: action.payload
			};
		case 'SETWISHLISTTP':
			return {
				...state,
				wishlistTP: action.payload
			};
		case 'TOGGLEETA':
			return {
				...state,
				etaEnabled: action.payload
			};
		case 'SETGENERALS':
			return {
				...state,
				language: action.payload.generals.language,
				wishlistLength: action.payload.generals.wishlistLength,
				etaEnabled: action.payload.generals.etaEnabled,
				name: action.payload.generals.name,
				_id: action.payload._id,
				retrievedBasics: true
			};
		case 'SETCRUMBLES':
			return {
				...state,
				crumbles: action.payload
			};
		case 'SETID':
			return {
				...state,
				_id: action.payload
			};
		case 'OPENKEYBOARD':
			return {
				...state,
				keyboardOpen: action.payload
			};
		case 'SETKEYBOARDTEXT':
			return {
				...state,
				keyboardText: action.payload
			};
		case 'SETGENERALFACETS':
			return {
				...state,
				generalFacets: action.payload
			};
		default:
			return state;
	}
};

