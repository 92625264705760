import Axios from 'axios';
import { dataEndpoint, addItemToWishlistEndpoint, addItemsToWishlistEndpoint } from '../config/ptc-config';
import { returnProductGroupOrCombinedProductGroup } from './helpers';
import { itemEndpoint } from './../config/ptc-config';

export const getItems = (pageNumber, productGroup, _id, sort) => {
	return Axios.post(dataEndpoint, { pageAmount: 60, pageNumber, productGroup: returnProductGroupOrCombinedProductGroup(productGroup), _id, sort }).then(res => res.data);
};

export const getItem = (sku, _id) => {
	return Axios.post(itemEndpoint, { sku, _id }).then(res => res.data);
};

export const addItemToWishlist = (item, ZUILID) => {
	return Axios.post(addItemToWishlistEndpoint, { wishlistItem: JSON.stringify(item), ZUILID }).then(res => res.data.amount);
};

export const addItemsToWishlist = (items, ZUILID) => {
	return Axios.post(addItemsToWishlistEndpoint, { wishlistItems: JSON.stringify(items), ZUILID }).then(res => res.data.amount);
};

export const isItemPartOfLongDelivery = (item) => {
	const longDeliveryProductgroups = ['CAT0000062']; // array of long delivery items
	if (item.eta && (item.stock !== undefined && item.stock < 1)) {
		return false;
	}
	for (const group of longDeliveryProductgroups) {
		if (group === item.campaign) {
			return true;
		}
	}
	return false;
};

export const isItemBlockSofa = (item) => {
	if (
		item['description.ENU'].includes('Block sofa')
		||
		item['description.ENU'].includes('block sofa')
		||
		item['description.ENU'].includes('Block Sofa')
	) {
		return 'sofalongdelivery';
	}
	return 'longdelivery';
};