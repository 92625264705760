import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const renderBackButtonList = ['productgroup', 'browse', 'item', 'wishlist', 'checkout', 'browsefound'];
const renderPaginatorList = ['browse', 'browsefound'];
const renderCheckoutButtonList = ['wishlist'];

const Fab = props => {
	const {RenderPaginator, RenderBackButton, RenderWishlistButton, RenderCheckoutButton, pageTitle, wishlistLength, RenderSort} = props;
	return (
		<Container fluid>
			<Row>
				<Col xs={7}>
					{renderBackButtonList.includes(pageTitle) && RenderBackButton()}
					{renderPaginatorList.includes(pageTitle) && RenderPaginator()}
				</Col>
				<Col xs={5} className='text-right'>
					{renderPaginatorList.includes(pageTitle) && RenderSort()}
					{wishlistLength > 0 && renderCheckoutButtonList.includes(pageTitle) && RenderCheckoutButton()}
					{pageTitle !== 'wishlist' && RenderWishlistButton()}
				</Col>
			</Row>
		</Container>
	);
};

export default Fab;