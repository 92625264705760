import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: {
				translation: {
					'sku': 'Productnumber',
					'Thisitemis': 'This item is sold per',
					'sold': '',
					'moreitemsavailable': 'Multiple items available within this collection',
					'search': 'Search',
					'loading': 'Loading items...',
					'amount': 'Quantity',
					'added': 'Added to list, price',
					'color': 'Color',
					'collection': 'Collection',
					'checkout': 'Place order',
					'thankyou': 'Thank you for your purchase!',
					'name': 'Name',
					'email': 'Email',
					'tel': 'Telephone',
					'pleaseentercreds': 'Make sure to fill in all the fields.',
					'newestcollection': 'Newest collection',
					'longdelivery': 'In 14 days',
					'sofalongdelivery': 'Inbetween 14 days - 30 days',
					'enterpin': 'Please enter your PIN',
					'invalidpin': 'invalid PIN',
					'allproduct': 'View all products here',
					'moresizes': 'more sizes',
					'othersviewed': 'Others also looked at',
					'stock': 'Stock',
					'starttyping': 'Start typing...',
					'noresults': 'No results, try',
					'eta': 'Available from',
					'privacy': 'Pot en Mand B.V. verwerkt geen persoonsgegevens buiten het afronden van dit bestelproces. Ook gebruiken we geen social media plugins. Nadat deze order is verwerkt zullen de klantgegevens periodiek verwijderd worden.'
				}
			},
			nl: {
				translation: {
					'sku': 'Productnummer',
					'Thisitemis': 'Dit item wordt per',
					'sold': 'verkocht',
					'search': 'Zoeken',
					'moreitemsavailable': 'Meerdere items beschikbaar binnen deze collectie',
					'loading': 'Producten laden...',
					'amount': 'Hoeveelheid',
					'added': 'Toegevoegd aan lijst, prijs',
					'color': 'Kleur',
					'collection': 'Collectie',
					'checkout': 'Bestelling plaatsen',
					'thankyou': 'Bedankt voor je aankoop!',
					'name': 'Naam',
					'email': 'Email',
					'tel': 'Telefoon',
					'pleaseentercreds': 'Vul a.u.b. alle velden in.',
					'newestcollection': 'Nieuwste collecties',
					'longdelivery': 'Binnen 14 dagen',
					'sofalongdelivery': 'Tussen 14 tot 30 dagen',
					'enterpin': 'Enter uw PIN',
					'invalidpin': 'foutieve PIN',
					'allproduct': 'Bekijk hier alle producten',
					'moresizes': 'meerdere maten',
					'othersviewed': 'Andere bekeken ook',
					'stock': 'Voorraad',
					'starttyping': 'Begin te typen...',
					'noresults': 'Geen resultaten, probeer',
					'eta': 'Leverbaar vanaf',
					'privacy': 'Pot en Mand B.V. verwerkt geen persoonsgegevens buiten het afronden van dit bestelproces. Ook gebruiken we geen social media plugins. Nadat deze order is verwerkt zullen de klantgegevens periodiek verwijderd worden.'
				}
			},
			de: {
				translation: {
					'sku': 'produktnummer',
					'search': 'Suche',
					'Thisitemis': 'Dieses Produkt wird per',
					'sold': 'verkauft',
					'moreitemsavailable': 'Mehrere Artikel in dieser Sammlung verfügbar',
					'loading': 'Produkte laden...',
					'amount': 'Menge',
					'added': 'Zur Liste hinzugefügt, Preis',
					'color': 'Farbe',
					'collection': 'Sammlung',
					'checkout': 'Bestellung abschicken',
					'thankyou': 'Vielen Dank für Ihren Einkauf!',
					'name': 'Name',
					'email': 'E-Mail',
					'tel': 'Telefonnummer',
					'pleaseentercreds': 'Bitte füllen Sie alle Felder aus.',
					'newestcollection': 'Neueste Kollektionen',
					'longdelivery': 'längere Lieferzeit!',
					'enterpin': 'Bitte PIN eingeben',
					'invalidpin': 'falscher Pin',
					'allproduct': 'Alle Artikel hier anzeigen.',
					'moresizes': 'mehrere größen',
					'othersviewed': 'Leute haben sich auch angesehen',
					'stock': 'Stock',
					'starttyping': 'Start typing...',
					'noresults': 'No results, try',
					'eta': 'Verfügbar ab',
					'privacy': 'Pot en Mand B.V. verarbeitet keine personenbezogenen Daten über den Abschluss dieses Bestellvorgangs hinaus. Wir verwenden auch keine Social Media Plugins. Nachdem diese Bestellung bearbeitet wurde, werden die Kundendaten in regelmäßigen Abständen gelöscht.'
				}
			},
			fa: {
				translation: {
					'sku': 'Numero de produit',
					'search': 'Recherche',
					'Thisitemis': 'This item is sold per',
					'sold': '',
					'loading': 'Charger des produits...',
					'amount': 'quantité',
					'added': 'Ajouté à la liste, prix',
					'moreitemsavailable': 'Plusieurs articles disponibles dans cette collection',
					'color': 'Couleur',
					'collection': 'Collection',
					'checkout': 'passer la commande',
					'thankyou': 'Merci pour votre achat!',
					'name': 'nom',
					'email': 'Email',
					'tel': 'numéro de téléphone',
					'pleaseentercreds': 'veuillez remplir tous les champs.',
					'stock': 'Stock',
					'starttyping': 'Start typing...',
					'noresults': 'No results, try',
					'eta': 'Disponible depuis',
					'privacy': 'Pot en Mand B.V. verwerkt geen persoonsgegevens buiten het afronden van dit bestelproces. Ook gebruiken we geen social media plugins. Nadat deze order is verwerkt zullen de klantgegevens periodiek verwijderd worden.'
				}
			},
			'en-GB': {
				translation: {
					'sku': 'Productnumber',
					'Thisitemis': 'This item is sold per',
					'sold': '',
					'moreitemsavailable': 'Multiple items available within this collection',
					'search': 'Search',
					'loading': 'Loading items...',
					'amount': 'Quantity',
					'added': 'Added to list, price',
					'color': 'Color',
					'collection': 'Collection',
					'checkout': 'Place order',
					'thankyou': 'Thank you for your purchase!',
					'name': 'Name',
					'email': 'Email',
					'tel': 'Telephone',
					'pleaseentercreds': 'Make sure to fill in all the fields.',
					'stock': 'Stock',
					'starttyping': 'Start typing...',
					'noresults': 'No results, try',
					'eta': 'Available from',
					'privacy': 'Pot en Mand B.V. verwerkt geen persoonsgegevens buiten het afronden van dit bestelproces. Ook gebruiken we geen social media plugins. Nadat deze order is verwerkt zullen de klantgegevens periodiek verwijderd worden.'
				}
			},
			'en-US': {
				translation: {
					'sku': 'Productnumber',
					'Thisitemis': 'This item is sold per',
					'sold': '',
					'moreitemsavailable': 'Multiple items available within this collection',
					'search': 'Search',
					'loading': 'Loading items...',
					'amount': 'Quantity',
					'added': 'Added to list, price',
					'color': 'Color',
					'collection': 'Collection',
					'checkout': 'Place order',
					'thankyou': 'Thank you for your purchase!',
					'name': 'Name',
					'email': 'Email',
					'tel': 'Telephone',
					'pleaseentercreds': 'Make sure to fill in all the fields.',
					'stock': 'Stock',
					'starttyping': 'Start typing...',
					'noresults': 'No results, try',
					'eta': 'Available from',
					'privacy': 'Pot en Mand B.V. verwerkt geen persoonsgegevens buiten het afronden van dit bestelproces. Ook gebruiken we geen social media plugins. Nadat deze order is verwerkt zullen de klantgegevens periodiek verwijderd worden.'
				}
			},
			'nl-NL': {
				translation: {
					'sku': 'Productnummer',
					'Thisitemis': 'Dit item wordt per',
					'sold': 'verkocht',
					'moreitemsavailable': 'Meerdere items beschikbaar binnen deze collectie',
					'search': 'Zoeken',
					'loading': 'Producten laden...',
					'amount': 'Hoeveelheid',
					'added': 'Toegevoegd aan lijst, prijs',
					'color': 'Kleur',
					'collection': 'Collectie',
					'checkout': 'Bestelling plaatsen',
					'thankyou': 'Bedankt voor je aankoop!',
					'name': 'Naam',
					'email': 'Email',
					'tel': 'Telefoon',
					'pleaseentercreds': 'Vul a.u.b. alle velden in.',
					'stock': 'Stock',
					'starttyping': 'Begin te typen...',
					'noresults': 'Geen resultaten, probeer',
					'eta': 'Leverbaar vanaf',
					'privacy': 'Pot en Mand B.V. verwerkt geen persoonsgegevens buiten het afronden van dit bestelproces. Ook gebruiken we geen social media plugins. Nadat deze order is verwerkt zullen de klantgegevens periodiek verwijderd worden.'
				}
			},
			'de-DE': {
				translation: {
					'sku': 'produktnummer',
					'moreitemsavailable': 'Mehrere Artikel in dieser Sammlung verfügbar',
					'search': 'Suche',
					'Thisitemis': 'Dieses Produkt wird per',
					'sold': 'verkauft',
					'loading': 'Produkte laden...',
					'amount': 'Menge',
					'added': 'Zur Liste hinzugefügt, Preis',
					'color': 'Farbe',
					'collection': 'Sammlung',
					'checkout': 'Bestellung abschicken',
					'thankyou': 'Vielen Dank für Ihren Einkauf!',
					'name': 'Name',
					'email': 'E-Mail',
					'tel': 'Telefonnummer',
					'pleaseentercreds': 'Bitte füllen Sie alle Felder aus.',
					'longdelivery': 'lange Lieferzeit!',
					'enterpin': 'Bitte PIN eingeben',
					'invalidpin': 'falscher Pin',
					'stock': 'Stock',
					'starttyping': 'Start typing...',
					'noresults': 'No results, try',
					'eta': 'Verfügbar ab',
					'privacy': 'Pot en Mand B.V. verarbeitet keine personenbezogenen Daten über den Abschluss dieses Bestellvorgangs hinaus. Wir verwenden auch keine Social Media Plugins. Nachdem diese Bestellung bearbeitet wurde, werden die Kundendaten in regelmäßigen Abständen gelöscht.'
				}
			},
			'fa-FA': {
				translation: {
					'sku': 'Numero de produit',
					'search': 'Recherche',
					'moreitemsavailable': 'Plusieurs articles disponibles dans cette collection',
					'loading': 'Charger des produits...',
					'amount': 'quantité',
					'added': 'Ajouté à la liste, prix',
					'color': 'Couleur',
					'collection': 'Collection',
					'checkout': 'passer la commande',
					'thankyou': 'Merci pour votre achat!',
					'name': 'nom',
					'email': 'Email',
					'tel': 'numéro de téléphone',
					'pleaseentercreds': 'veuillez remplir tous les champs.',
					'stock': 'Stock',
					'starttyping': 'Start typing...',
					'noresults': 'No results, try',
					'eta': 'Disponible depuis',
					'privacy': 'Pot en Mand B.V. verwerkt geen persoonsgegevens buiten het afronden van dit bestelproces. Ook gebruiken we geen social media plugins. Nadat deze order is verwerkt zullen de klantgegevens periodiek verwijderd worden.'
				}
			}
		},
		lng: 'en',
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		}
	});
const languageMap = {
	'nl-NL': 'NLD',
	'nl': 'NLD',
	'en-US': 'ENU',
	'en-GB': 'ENU',
	'en': 'ENU',
	'de': 'DEU',
	'de-DE': 'DEU',
	'fr': 'FRA',
	'fr-FR': 'FRA'
};

// if(window.location.hostname.includes('beta') || window.location.hostname.includes('localhost')) {
// 	i18n.changeLanguage('nl');
// }
export const translate = (object, lang) => {
	return object[languageMap[lang]];
};
export const translateItems = (object, lang) => {
	return object[`description.${languageMap[lang]}`];
};
