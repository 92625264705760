
export const CATEGORIES = [
	{
		'code': '1',
		'names': {
			'NLD': 'Meubels',
			'DEU': 'Möbel',
			'FRA': 'Meubles',
			'ENU': 'Furniture'

		},
		'image': 'Correct/Meubels1.jpg'
	},
	{
		'code': '10',
		'names': {
			'FRA': 'Accessoires',
			'DEU': 'Zubehör',
			'NLD': 'Accessoires',
			'ENU': 'Accessories'
		},
		'image': 'Correct/Accessoires1.jpg'
	},
	{
		'code': '11',
		'names': {
			'FRA': 'Luminaires',
			'DEU': 'Beleuchtung',
			'NLD': 'Verlichting',
			'ENU': 'Lighting'
		},
		'image': 'wysiwyg/wysiwyg2_verlichting'
	},
	{
		'code': '3',
		'names': {
			'FRA': 'Pots & Bols',
			'DEU': 'Töpfe & Schalen',
			'NLD': 'Potten & Schalen',
			'ENU': 'Pots & Bowls'
		},
		'image': 'Correct/Pottenenschalen1.jpg'
	},
	{
		'code': '4',
		'names': {
			'FRA': 'Cuisine',
			'DEU': 'Küche',
			'NLD': 'Keuken',
			'ENU': 'Kitchen'
		},
		'image': 'wysiwyg/wysiwyg2_keuken'
	},
	{
		'code': '5',
		'names': {
			'FRA': 'Déco murale',
			'DEU': 'Wanddekoration',
			'NLD': 'Wanddecoratie',
			'ENU': 'Wall decoration'
		},
		'image': 'Correct/Wanddecoratie1.jpg'
	},
	{
		'code': '6',
		'names': {
			'FRA': 'Plantes artificielle',
			'DEU': 'Kunstpflanzen',
			'NLD': 'Kunstplanten',
			'ENU': 'Artificial plants'
		},
		'image': 'wysiwyg/wysiwyg1_bloemen'
	},
	{
		'code': '7',
		'names': {
			'FRA': 'Tissus',
			'DEU': 'Textilien',
			'NLD': 'Stoffen',
			'ENU': 'Fabrics'
		},
		'image': 'Correct/Stoffenenverf1.jpg'
	}
];
