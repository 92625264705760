import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter as Router } from 'connected-react-router';
// import BrowsePage from '../pages/browsepage.js';
import history from './history';
import CategoryContainer from '../containers/category';
import ProductGroupContainer from '../containers/productGroup';
import HeaderContainer from './../containers/header';
import BrowseContainer from './../containers/browse';
import ItemContainer from './../containers/item';
import WishlistContainer from './../containers/wishlist';
import CheckoutContainer from './../containers/checkout';
import BrowseFoundContainer from './../containers/browseFound';
export class CustomRouter extends Component {
	render() {
		return (
			<Router history={history} >
				<>
				<Route path='/:zuilid' component={HeaderContainer} />
				<Switch>
					<Route exact path='/:zuilid/wishlist/checkout' component={CheckoutContainer} />
					<Route exact path='/:zuilid/wishlist' component={WishlistContainer} />
					<Route exact path='/:zuilid/browse/:category' component={ProductGroupContainer} />
					<Route exact path='/:zuilid/browse/:category/:productgroup' component={BrowseContainer} />
					<Route exact path='/:zuilid/item/:sku' component={ItemContainer} />
					<Route exact path='/:zuilid/search' component={BrowseFoundContainer} />
					<Route exact path='/:zuilid' component={CategoryContainer} />
				</Switch>
				</>
			</Router>
		);
	}
}