import React from 'react';
import '../styles/suggestionCard.css';

export default props => {
	const {type, value, item, chips, onClick} = props;
	return <div className='cardHolder' onClick={onClick}>
		<div className='card'>
			<div className='left'>
				<span className='type'>{value}</span>
			</div>
			<div className='right'>
				{
					item &&
                <>
                    {chips.find(c => c.type === 'colorSuggester') && <div className='extra'>{item.color}</div>}
                    <img src={item.imageSmall}></img>
                </>

				}
				{
					type === 'colorSuggester' && <div className='colorBox' style={{backgroundColor: value.toLowerCase()}}></div>
				}
			</div>
		</div>
	</div>;
};