import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import '../styles/browse.css';
import Browse from '../views/browse';
import { setItems } from './../actions/actions';
import withPage from '../hoc/withPage';
import { useTranslation } from 'react-i18next';
import { conductSearch } from '../api/search';

const BrowseFoundContainer = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const {
		_id,
		items
	} = useSelector(
		state => ({
			_id: state.main._id,
			items: state.main.items
		})
	);
	const history = useHistory();
	const [page] = useQueryParam('page', StringParam);
	const [order] = useQueryParam('order', StringParam);
	const [sort] = useQueryParam('sort', StringParam);
	const [sortOption] = useQueryParam('sortOption', StringParam);
	const [searchQuery] = useQueryParam('searchQuery', StringParam);
	const dispatchProductItemPage = sku => {
		history.push(`/${_id}/item/${sku}`);
	};
	useEffect(() => {

		if (sort) {
			dispatch({type: 'RESETITEMS'});
			conductSearch(_id, searchQuery, page || 1, {order, on: sort === 'true' ? true : false, option: sortOption}).then(i => {
				dispatch(setItems(i));
			});
		} else {
			dispatch({type: 'RESETITEMS'});
			conductSearch(_id, searchQuery, page || 1).then(i => {
				dispatch(setItems(i));
			});
		}
	}, []);
	return (
		<Browse
			items={items}
			dispatchProductItemPage={dispatchProductItemPage}
			t={t}
			i18n={i18n}
		/>
	);
};

export default withRouter(withPage({title: 'browsefound'})(BrowseFoundContainer));