
import Axios from 'axios';
import { getWishlistEndpoint, removeItemAmountFromWishlistEndpoint, removeSofaGroupFromWishlistEndpoint, addItemAmountToWishlistEndpoint, removeItemFromWishlistEndpoint } from './../config/ptc-config';

export const getWishlist = (_id) => {
	return Axios.get(`${getWishlistEndpoint}/${_id}`).then(res => res.data);
};

export const removeFromWishlist = (_id, sku) => {
	return Axios.post(removeItemFromWishlistEndpoint, {ZUILID: _id, wishlistItemSKU: sku}).then(res => res.data);
};

export const removeSofaFromWishlist = (_id, sofaGroup) => {
	return Axios.post(removeSofaGroupFromWishlistEndpoint, {ZUILID: _id, sofaGroup}).then(res => res.data);
};

export const wishlistItemPlus = (_id, sku) => {
	return Axios.post(addItemAmountToWishlistEndpoint, {ZUILID: _id, wishlistItemSKU: sku}).then(res => res.data);
};

export const wishlistItemMin = (_id, sku) => {
	return Axios.post(removeItemAmountFromWishlistEndpoint, {ZUILID: _id, wishlistItemSKU: sku}).then(res => res.data);
};