import React from 'react';
import Fab from '@material-ui/core/Fab';
import PaginatorContainer from './paginator';
import { withRouter, useHistory, useRouteMatch } from 'react-router-dom';
import Fabbar from '../views/fab';
import '../styles/fab.css';
import { useQueryParam, StringParam } from 'use-query-params';
import { useSelector } from 'react-redux';
import { skipGroups } from '../hardcodedFiles/skipGroups';
import { useTranslation } from 'react-i18next';

const FabContainer = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const match = useRouteMatch({
		path: '/:zuilid/browse/:categoryId/:productGroupId'
	});
	const {
		pageTitle,
		path,
		wishlistLength,
		_id,
		wishlistTP
	} = useSelector(
		state => ({
			pageTitle: state.main.pageTitle,
			path: state.router.location.pathname,
			wishlistLength: state.main.wishlistLength,
			_id: state.main._id,
			wishlistTP: state.main.wishlistTP,
			crumbles: state.main.crumbles
		})
	);
	const [order] = useQueryParam('order', StringParam);
	const [sortOption] = useQueryParam('sortOption', StringParam);
	const [page] = useQueryParam('page', StringParam);
	const [searchQuery] = useQueryParam('searchQuery', StringParam);
	const RenderBackButton = () => {
		const zuilid = path.split('/')[1];
		let goBackRoute;
		switch (pageTitle) {
			case 'productgroup':
				goBackRoute = `/${zuilid}`;
				break;
			case 'browse':
				if (skipGroups.find(x => +x.code === +path.split('/')[3])) {
					goBackRoute = `/${zuilid}`;

				} else {
					goBackRoute = `/${zuilid}/browse/${path.split('/')[3]}`;
				}
				break;
			case 'item':
				goBackRoute = undefined;
		}

		return (
			<Fab className='fabBack' onClick={() => { return goBackRoute ? history.push(goBackRoute) : history.goBack(); }}>
				<i className="fas fa-arrow-left backIcon" />
			</Fab>
		);
	};
	const filter = (sortType) => {
		let prefix;
		let query;
		let sortingOption;
		if (sortType === 'price') {
			sortingOption = 'price';
		} else if (sortType === 'date') {
			sortingOption = 'sku';
		}
		if (['browsefound'].includes(pageTitle)) {
			prefix = `/${_id}/search`;
			if (sortType === 'erase') {
				query = `?page=${page || 1}&search=on&searchQuery=${searchQuery}`;
			} else {
				query = `?page=${page || 1}&search=on&searchQuery=${searchQuery}&sort=true&sortOption=${sortingOption}&order=${order === 'asc' ? 'desc' : 'asc'}`;
			}
		} else {
			prefix = match.url;
			if (sortType === 'erase') {
				query = `?page=${page || 1}`;
			} else {
				query = `?page=${page || 1}&sort=true&sortOption=${sortingOption}&order=${order === 'asc' ? 'desc' : 'asc'}`;
			}
		}
		history.push(`${prefix}${query}`);

	};
	const RenderSort = () => {
		return <>
		<Fab className={sortOption === 'sku' ? 'filterActive' : 'filterInactive'} onClick={() => filter('date')}>
			<i className="fas fa-calendar-alt"></i> {order === 'asc' ?<i className="fas fa-long-arrow-alt-up"></i> : <i className="fas fa-long-arrow-alt-down"></i>}
		</Fab>
		<Fab className={sortOption === 'price' ? 'filterActive' : 'filterInactive'} onClick={() => filter('price')}>
			<i className="fas fa-euro-sign"></i> {order === 'asc' ?<i className="fas fa-long-arrow-alt-up"></i> : <i className="fas fa-long-arrow-alt-down"></i>}
		</Fab>
		<Fab className={'sortTrash'} onClick={async () => filter('erase')}>
			<i className="fas fa-trash"></i>
		</Fab>
		</>;
	};
	const RenderPaginator = () => {
		return <Fab variant="extended" className='fabNav' >
			<PaginatorContainer />
		</Fab>;
	};
	const RenderWishlistButton = () => {
		return <Fab variant={wishlistLength && wishlistLength !== 0 ? 'extended' : 'round' } className='fabWishlist' onClick={() => { history.push(`/${_id}/wishlist`); }} >
			<i className="fas fa-shopping-cart"></i>
			{wishlistLength && wishlistLength !== 0 ? <span className="counter">{wishlistLength}</span> : null}
		</Fab>;
	};
	const RenderCheckoutButton = () => {
		return <Fab variant='extended' className='fabCheckout' onClick={() => { history.push(`/${_id}/wishlist/checkout`); }} >
			{wishlistTP && wishlistTP > 0 ? `${t('checkout')}: ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(wishlistTP)}` : ''}
		</Fab>;
	};

	return (
		<Fabbar
			RenderPaginator={RenderPaginator}
			RenderBackButton={RenderBackButton}
			RenderWishlistButton={RenderWishlistButton}
			pageTitle={pageTitle}
			RenderCheckoutButton={RenderCheckoutButton}
			wishlistLength={wishlistLength}
			RenderSort={RenderSort}
			t={t}
			i18n={i18n}
		/>
	);
};

export default withRouter(FabContainer);