import React from 'react';
import { Col } from 'react-bootstrap';
import { translate } from './../hardcodedFiles/translationHelper';
import combined from '../hardcodedFiles/combinedProductGroups';
// import { externalLinks } from './../hardcodedFiles/external';

const ProductGroup = props => {
	const {data, dispatchProductGroupCode, i18n, facet, goToCustom} = props;
	return data ? data.map((item, index) => {
		const {productgroup, image, names} = item;
		let combinedValue;
		if (combined[productgroup]) {
			combinedValue = combined[productgroup].reduce((acc, val) => {
				let found;
				if (facet) {
					found = facet.find(f => +f.name === +val);
				}
				if (found && found.count) {
					return acc + found.count;
				}
			}, 0);
		}
		const foundCount = facet ? facet.find(f => +f.name === +productgroup) : null;
		const count = foundCount ? foundCount.count : null;
		const dispatch = pg => () => dispatchProductGroupCode(pg);
		/*
		const dispatch = (productgroup) => {
			const exLMatch = externalLinks.find(eL => +eL.code === +productgroup)
			if (exLMatch) {
				goToCustom(exLMatch.link);
			} else {
				dispatchProductGroupCode(productgroup);
			}
		};
		*/
		return (
			<Col key={index} onClick={dispatch(productgroup)}
				className='col-pg' sm={6} md={6}>
				<div className="wrapper-pg">
					<div className="clash-card-pg barbarian-pg">
						<div className="clash-card__image-pg clash-card__image--barbarian-pg">
							<img src={image} alt={index}></img>
						</div>
						<div className="clash-card__level clash-card__level--barbarian">{translate(names, i18n.language || window.localStorage.i18nextLng)} <span className='count'>{combinedValue ? `(${combinedValue})` : count ? `(${count})` : ''}</span></div>
					</div>
				</div>
			</Col>
		);
	}) : null;
};

export default ProductGroup;