import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import Paginator from '../views/paginator';
import '../styles/paginator.css';
import { pageAmount } from './../config/ptc-config';
const PaginatorContainer = () => {
	const match = useRouteMatch({
		path: '/:zuilid/browse/:categoryId/:productGroupId'
	});
	const {
		numFound,
		pageTitle,
		_id
	} = useSelector(
		state => ({
			numFound: state.main.numFound,
			pageTitle: state.main.pageTitle,
			_id: state.main. _id
		})
	);
	const history = useHistory();
	const [page] = useQueryParam('page', StringParam);
	const [searchQuery] = useQueryParam('searchQuery', StringParam);
	const [order] = useQueryParam('order', StringParam);
	const [sort] = useQueryParam('sort', StringParam);
	const [sortOption] = useQueryParam('sortOption', StringParam);
	const fetchItems = (newPage) => {
		let prefix;
		let query;
		if (['browsefound'].includes(pageTitle)) {
			prefix = `/${_id}/search`;
			if (sort) {
				query = `?page=${newPage}&sort=${sort}&sortOption=${sortOption}&order=${order}&searchQuery=${searchQuery}`;
			} else {
				query = `?page=${newPage}&searchQuery=${searchQuery}`;
			}
		} else {
			prefix = match.url;
			if (sort) {
				query = `?page=${newPage}&sort=${sort}&sortOption=${sortOption}&order=${order}`;
			} else {
				query = `?page=${newPage}`;
			}
		}
		history.push(`${prefix}${query}`);
	};
	return (
		<Paginator
			updateCurrentPage={fetchItems}
			currentpage={+page || 1}
			maxPageNumber={numFound ? Math.ceil(numFound / pageAmount) : 1}
		/>
	);
};

export default PaginatorContainer;